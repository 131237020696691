import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/language';

const Text = (props) => {
  const { id } = props;

  const { dictionary, defaultDictionary } = useContext(LanguageContext);

  return (
    <>
      {dictionary[id] ? dictionary[id] : defaultDictionary[id]}
    </>
  );
};

export default Text;
