/* eslint-disable max-len */
import React, { createContext, useContext } from 'react';
import { isIOS } from 'react-device-detect';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { BookingContext } from './booking';

export const BoardingPassContext = createContext();

export const BoardingPassProvider = (props) => {
  const { children } = props;

  const {
    booking,
  } = useContext(BookingContext);

  const fetchBoardingPass = (request) => axios({
    url: `${process.env.REACT_APP_GENERATE_BOARDING_PASS_API}/pdf`,
    method: 'POST',
    responseType: 'blob',
    headers: {
      Authorization: booking.token,
    },
    data: request,
  })
    .then((response) => {
      const type = isIOS ? null : { type: 'application/pdf' };
      const url = window.URL.createObjectURL(new Blob([response.data], type));
      const link = document.createElement('a');
      // link.href = url;
      link.setAttribute('href', url);
      link.setAttribute('download', 'boardingPass.pdf');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      return 'ok';
    }).catch((e) => Sentry.captureException(e));

  const fetchBoardingPassMail = (request) => axios({
    url: `${process.env.REACT_APP_GENERATE_BOARDING_PASS_API}/mail`,
    method: 'POST',
    headers: {
      Authorization: booking.token,
      'Content-Type': 'application/json',
    },
    data: request,
  })
    .then(() => 'ok').catch((e) => Sentry.captureException(e));

  const fetchBoardingAppleWallet = (request) => axios({
    url: `${process.env.REACT_APP_GENERATE_BOARDING_PASS_API}/appleWallet`,
    method: 'POST',
    responseType: 'blob',
    headers: {
      Authorization: booking.token,
      'Content-Type': 'application/json',
    },
    data: request,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.apple.pkpass' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'boardingPass.pkpass');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      return 'ok';
    }).catch((e) => Sentry.captureException(e));

  const fetchBoardingGoogleWallet = (request) => axios({
    url: `${process.env.REACT_APP_GENERATE_BOARDING_PASS_API}/googleWallet`,
    method: 'POST',
    // responseType: 'blob',
    headers: {
      Authorization: booking.token,
      'Content-Type': 'application/json',
    },
    data: request,
  })
    .then((response) => {
      console.log(response.data);
      return response.data;
    }).catch((e) => Sentry.captureException(e));

  const values = {
    fetchBoardingPass,
    fetchBoardingPassMail,
    fetchBoardingAppleWallet,
    fetchBoardingGoogleWallet,
  };

  return (
    <BoardingPassContext.Provider value={values}>
      {children}
    </BoardingPassContext.Provider>
  );
};
