import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { getDate, getTimeDifferenceWithTimeZone } from '../../../utils/date';
import { ReactComponent as AirplaneIcon } from '../../../assets/img/planeRight.svg';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#E0F4fD',
    padding: 10,
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flightIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0px 5px 0px',
  },
  dashedLine: {
    backgroundColor: (props) => (`${props.dashedLineColor !== 'black'}` ? '' : '#FFF'),
    color: '#FFF',
    border: 'none',
    borderTop: (props) => `1px dashed ${props.dashedLineColor ? props.dashedLineColor : 'black'}`,
    height: 1,
  },
  header: {
    fontSize: 9,
  },
  stationCode: {
    fontSize: 34,
    paddingBottom: 0,
  },
  stationName: {
    fontSize: 11,
  },
});

const FlightRoute = (props) => {
  const classes = useStyles(props);

  const {
    className,
    flightNumber,
    scheduledDeparture,
    scheduledArrival,
    departureStationCode,
    departureStationName,
    departureTimeZone,
    arrivalStationCode,
    arrivalStationName,
    arrivalTimeZone,
  } = props;
  const timeDiff = getTimeDifferenceWithTimeZone(scheduledDeparture,
    scheduledArrival, departureTimeZone, arrivalTimeZone);

  return (
    <Grid container className={clsx(classes.root, className)}>
      <Grid item xs={4} className={classes.centered}>
        <Typography variant="subtitle1" className={classes.header}>{getDate(scheduledDeparture)}</Typography>
        <Typography variant="h2" className={classes.stationCode}>{departureStationCode || 'N/A'}</Typography>
        <Typography variant="subtitle1" className={classes.stationName}>{departureStationName}</Typography>
      </Grid>
      <Grid item xs={4} className={classes.centered}>
        {(timeDiff !== '0h 00min') && <Typography variant="subtitle1" className={classes.header}>{timeDiff}</Typography>}
        <Grid container className={classes.flightIconContainer}>
          <Grid item xs={4}>
            <hr className={classes.dashedLine} />
          </Grid>
          <Grid item xs={4} className={classes.centered}>
            <AirplaneIcon />
          </Grid>
          <Grid item xs={4}>
            <hr className={classes.dashedLine} />
          </Grid>
        </Grid>
        <Typography variant="subtitle2">{flightNumber}</Typography>
      </Grid>
      <Grid item xs={4} className={classes.centered}>
        <Typography variant="subtitle1" className={classes.header}>{getDate(scheduledArrival)}</Typography>
        <Typography variant="h2" className={classes.stationCode}>{arrivalStationCode}</Typography>
        <Typography variant="subtitle1" className={classes.stationName}>{arrivalStationName}</Typography>
      </Grid>
    </Grid>
  );
};

FlightRoute.propTypes = {
  scheduledDeparture: PropTypes.string,
  scheduledArrival: PropTypes.string,
  departureStationCode: PropTypes.string,
  departureStationName: PropTypes.string,
  arrivalStationCode: PropTypes.string,
  arrivalStationName: PropTypes.string,
};

export default FlightRoute;
