import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '../../components/UI/Dialog/Dialog';
import { BookingContext } from '../../context/booking';
import Text from '../../components/UI/Text/Text';

const SelectPassenger = () => {
  const [open, setOpen] = useState(true);

  const {
    activePassengers,
    setActivePassengers,
    unfilteredBooking,
    passengerIdentifier,
  } = useContext(BookingContext);

  const toggle = (id) => {
    const newActivePassenger = activePassengers.map((l) => (l));
    newActivePassenger[id] = !activePassengers[id];
    setActivePassengers(newActivePassenger);
  };

  useEffect(() => {
    if (unfilteredBooking && open && unfilteredBooking.primeJourney[0].passengers.length === 1) {
      setActivePassengers([true]);
      setOpen(false);
    }
  }, [activePassengers.length, setActivePassengers,
    unfilteredBooking, passengerIdentifier, activePassengers, open]);

  return (
    <>
      <Dialog
        maxWidth="xs"
        setOpen={() => ''}
        open={open}
        PaperComponent={Box}
      >
        {unfilteredBooking && (
          <Box px={1} pb={{ xs: 1, sm: 3 }} mr={{ xs: 0, md: 2 }} style={{ background: '#fff' }}>
            <Box fontSize={30} pb={{ xs: 1 }} fontWeight={800}>
              <Typography variant="inherit"><Text id="select-passengers-dialog-title" /></Typography>
            </Box>
            {unfilteredBooking.primeJourney[0].passengers.map((pax, i) => (
              (pax.type !== 'IN') && (
                <Box mb={1} key={pax.passengerIdentifier}>
                  <FormControlLabel
                    control={(
                    activePassengers && (
                      <Checkbox
                        color="secondary"
                        onChange={() => toggle(i)}
                        checked={activePassengers[i]}
                        size="medium"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    ))}
                    label={(
                      <Box mb={1}>
                        <Box key={pax.name.fullName}>
                          {pax.name.fullName}
                        </Box>
                        {pax.infant && (
                        <Box key={pax.infant.name.fullName}>
                          {`with infant  (${pax.infant.name.fullName})`}
                        </Box>
                        )}
                      </Box>
                    )}
                  />
                </Box>
              )
            ))}
          </Box>
        )}
        <Button
          fullWidth
          disabled={!(activePassengers && activePassengers.find((x) => x))}
          color="secondary"
          variant="contained"
          onClick={(() => {
            setOpen(false);
          })}
        >
          <Text id="footer-continue-button" />
        </Button>
      </Dialog>
    </>
  );
};

export default SelectPassenger;
