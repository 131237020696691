import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  aile: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginRight: theme.spacing(0.5),
    fontSize: '12px',
    color: '#082a36',
    fontWeight: 300,
    maxWidth: '50px',

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const AisleCol = (props) => {
  const classes = useStyles();

  // Props
  const { aile } = props;

  return (
    <Typography className={clsx(classes.aile)}>
      {aile && aile !== 0 ? aile : ''}
    </Typography>
  );
};

AisleCol.propTypes = {
  aile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default AisleCol;
