import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SeatCol from './SeatCol/SeatCol';
import AisleCol from './AisleCol/AisleCol';
import EmptyCol from './EmptyCol/EmptyCol';
import SeatRowExit from './SeatRowExit/SeatRowExit';

// Styles
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'center',

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

const SeatRow = (props) => {
  const classes = useStyles();

  // Props
  const {
    row, cols, aisles, exitRows, selectedSeats, initialSeats,
    selectSeat, height, flight,
  } = props;

  // Header or content
  const content = [];
  if (!row) {
    // Header
    cols.forEach((column) => {
      content.push(
        <AisleCol
          key={column}
          aile={column}
        />,
      );
      // Add aisle
      if (aisles.some((aisle) => aisle === column)) {
        content.push(<AisleCol key={`aisle-${column}`} />);
      }
    });
  } else {
    // Content
    cols.forEach((column) => {
      // Get index for seat
      const index = row.seats.findIndex((seat) => seat.seatColName === column);

      // Check if seat exits
      if (index !== -1) {
        // Check if seat is empty
        if (row.seats[index].seatStatus.code === 'NO_SEAT') {
          content.push(<EmptyCol key={index} />);
        } else {
          content.push(
            <SeatCol
              key={index}
              seatStatus={row.seats[index].seatStatus}
              seatTypes={row.seats[index].seatTypes}
              seatNr={row.seats[index].rowNumber + row.seats[index].seatColName}
              seatCol={row.seats[index].seatColName}
              seatRow={row.seats[index].rowNumber}
              passenger={row.seats[index].passengerInfo}
              selectedSeats={selectedSeats}
              initialSeats={initialSeats}
              selectSeat={selectSeat}
              flight={flight}
            />,
          );
        }

        // Add aisle
        if (aisles.some((aisle) => aisle === row.seats[index].seatColName)) {
          content.push(
            <AisleCol
              key={`aisle-${index}`}
              aile={row.seats[index].rowNumber}
            />,
          );
        }
      } else {
        content.push(<EmptyCol key={index} />);
      }
    });
  }

  // Exit row
  let exitRow;
  if (exitRows && exitRows.includes(row.rowNr)) {
    exitRow = <SeatRowExit height={height} colMaxWidth={content.length * 55} />;
  }

  return (
    <>
      {exitRow}
      <Grid className={classes.row} style={{ height }}>
        {content}
      </Grid>
    </>
  );
};

SeatRow.propTypes = {
  row: PropTypes.object,
  cols: PropTypes.array,
  aisles: PropTypes.array,
  exitRows: PropTypes.array,
  selectSeat: PropTypes.func,
  selectedSeats: PropTypes.array,
  height: PropTypes.string,
  initialSeats: PropTypes.array,
  flight: PropTypes.object,
};

export default SeatRow;
