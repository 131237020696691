const typography = (muiTheme) => ({
  fontFamily: 'Lato-Regular',
  body1: {
    fontFamily: 'Lato-Regular',
    fontSize: 18,
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  body2: {
    fontFamily: 'Lato-Regular',
    fontSize: 15,
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  h1: {
    paddingBottom: 8,
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 28,
  },
  h2: {
    paddingBottom: 8,
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 48,
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: 22,
      fontFamily: 'Lato-Regular',
    },
  },
  h3: {
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 18,
  },
  h4: {
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 16,
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  h5: {
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 14,
    [muiTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  h6: {
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    fontSize: 12,
    [muiTheme.breakpoints.down('sm')]: {
      fontFamily: 'Lato-Regular',
      fontSize: 10,
    },
  },
  overline: {
    fontFamily: 'Lato-Regular',
    fontSize: 12,
  },
  subtitle1: {
    fontFamily: 'Lato-Regular',
    fontSize: 11,
  },
  subtitle2: {
    fontFamily: 'Lato-Regular',
    fontWeight: 800,
    color: '#000',
    fontSize: 11,
  },
  caption: {
    fontFamily: 'Lato-Regular',
    fontSize: 11,
  },
}
);

export default typography;
