const colors = {
  background: {
    main: '#F8F8FB',
  },
  lightBackground: {
    main: '#e0f4fd',
  },
  primary: {
    main: '#C4122F',
  },
  secondary: {
    main: '#1CB3EF',
  },
  error: {
    main: '#C4122F',
  },
  success: {
    main: '#00A411',
  },
  text: {
    primary: '#000',
    secondary: '#FFF',
  },
  warning: {
    main: '#EBA400',
  },
  input: {
    background: '#FFF',
    label: '#000',
    border: '#000',
  },
  alternative: {
    main: '#082a36',
    contrast: '#82ead9',
  },
  stepper: {
    icon: '#FFF',
    line: '#FFF',
    text: '#FFF',
  },
};

export default colors;
