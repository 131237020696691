import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Text from '../../Text/Text';

const useStyles = makeStyles({
  root: {
    padding: 10,
  },
  header: {
    fontSize: 9,
    color: '#082A36',
  },
  name: {
    textTransform: 'capitalize',
  },
});

const PassengerSummary = (props) => {
  const classes = useStyles();

  const {
    passengers,
  } = props;

  return (
    <Box px={3} py={2}>
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-table-header-name" /></Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-table-header-status" /></Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-table-header-seat" /></Typography>
        </Grid>
      </Grid>
      {passengers && passengers.map((passenger) => (
        <Grid container key={passenger.passengerFlightIdentifier}>
          <Grid item xs={7}>
            <Typography variant="subtitle1" className={classes.name}>{passenger.name ? `${passenger.name.firstName} ${passenger.name.surName}`.toLowerCase() : '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color={passenger.checkin ? 'secondary' : 'error'}>{passenger.checkin ? 'Checked in' : 'Not checked in'}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{passenger.seat || '-'}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

PassengerSummary.propTypes = {
  passengers: PropTypes.array,
};

export default PassengerSummary;
