import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import Navigation from '../../hoc/Navigation/Navigation';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';
import BoardingPassButton from '../../components/UI/BoardingPassButton/BoardingPassButton';

import BoardingPassMail from '../../components/BoardingPass/BoardingPassMail';
import BoardingPassWallet from '../../components/BoardingPass/BoardingPassWallet';
import Timmisa from '../../components/BoardingPass/Timmisa';
import BoardingPassDownload from '../../components/BoardingPass/BoardingPassDownload';
import { ReactComponent as Email } from '../../assets/img/email.svg';
// import { ReactComponent as Sms } from '../../assets/img/sms.svg';
import { ReactComponent as Download } from '../../assets/img/download.svg';

const BoardingPass = () => {
  const history = useHistory();
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openMail, setOpenMail] = React.useState(false);

  const {
    setStep,
    appEmbedded,
  } = useContext(NavigationContext);

  const {
    booking,
  } = useContext(BookingContext);

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  useEffect(() => {
    if (!booking) {
      history.push(routes.BOOKING_SEARCH_NO_BOOKING);
    }
  }, [booking, history]);

  return (
    <>
      <BoardingPassMail open={openMail} setOpen={setOpenMail} />
      <BoardingPassDownload open={openDownload} setOpen={setOpenDownload} />
      <Container maxWidth="sm">
        <Box mt={2} pb={5}>
          <Box mb={5}>
            <Typography variant="h2"><Text id="boarding-pass-title" /></Typography>
            <Typography><Text id="boarding-pass-text" /></Typography>

            <Box my={2}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <BoardingPassButton
                    onClick={(() => setOpenMail(true))}
                    icon={<Email />}
                  >
                    <Text id="boarding-pass-card-action-send-email" />
                  </BoardingPassButton>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <BoardingPassButton
                    onClick={(() => setOpen('sms'))}
                    icon={<Sms />}
                  >
                    <Text id="boarding-pass-card-action-send-sms" />
                  </BoardingPassButton>
                </Grid> */}
                {!appEmbedded
                  ? (
                    <Grid item xs={12} md={4}>
                      <BoardingPassButton
                        onClick={(() => setOpenDownload(true))}
                        icon={<Download />}
                      >
                        <Text id="boarding-pass-card-action-download-pdf" />
                      </BoardingPassButton>
                    </Grid>
                  ) : null}
              </Grid>
            </Box>
          </Box>
          {!appEmbedded
            ? <BoardingPassWallet /> : null}
          <Timmisa />
          <Navigation
            backText={<Text id="footer-back-button" />}
            nextText={<Text id="footer-continue-button" />}
            backRoute={routes.SUMMARY}
            nextRoute={routes.BOOKING_SEARCH}
            nextAlink={appEmbedded && '/?didCompleteCheckin=true'}
            postMessage="/BOOKING_SEARCH"
          />
        </Box>
      </Container>
    </>
  );
};

export default BoardingPass;
