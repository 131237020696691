import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PassengerSummary from './PassengerSummary/PassengerSummary';
import FlightSummary from './FlightSummary/FlightSummary';

const DetailedSummary = (props) => {
  const {
    flight,
  } = props;

  return (
    <>
      <FlightSummary flight={flight} />
      <PassengerSummary
        passengers={flight.passengers}
      />
    </>
  );
};

DetailedSummary.propTypes = {
  flight: PropTypes.object,
};

export default DetailedSummary;
