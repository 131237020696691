import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import BoardingPassCard from './BoardingPassCard/BoardingPassCard';
import SummaryJourney from './SummaryJourney/SummaryJourney';

const BoardingPassCardList = (props) => {
  const {
    flights,
    toggle,
    journeyIndex,
    selectedJourney,
  } = props;

  const checkedInFlight = flights
    .find((flight) => flight.status === 'Open' && flight.passengers.find((passenger) => passenger.checkin))
    && flights.find((flight) => flight.passengers
      .find((passenger) => passenger.checkin)).passengers
      .map((passenger) => passenger.checkin);

  return (
    checkedInFlight ? (
      <Box pt={1}>
        <Box mb={0.5}>
          <SummaryJourney
            journeyIndex={journeyIndex}
            toggle={toggle}
            flights={flights}
            selectedJourney={selectedJourney}
          />
        </Box>
        {flights && flights.filter((flight) => flight && flight.status !== 'Departed'
          && flight.passengers.find((passenger) => passenger.checkin)).map((flight) => (
            <Box pb={0.5} key={flight.flightNr + flight.station.from.code}>
              <BoardingPassCard
                flight={flight}
              />
            </Box>
        ))}
      </Box>
    )
      : (<></>)
  );
};

BoardingPassCardList.propTypes = {
  flights: PropTypes.array,
  toggle: PropTypes.func,
};

export default BoardingPassCardList;
