import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';

/* eslint-disable react/jsx-props-no-spreading */
const SelectField = (props) => {
  const {
    items, id, variant, label,
  } = props;

  return (
    <TextField
      {...props}
      select
      label={label || null}
      id={id || 'select'}
      variant={variant || 'filled'}
    >
      {items.map((item, i) => <MenuItem key={item} value={i}>{item}</MenuItem>)}
    </TextField>
  );
};

SelectField.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  action: PropTypes.func,
  id: PropTypes.string,
  variant: PropTypes.string,
};

export default SelectField;
