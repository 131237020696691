import React, { useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import MenuIcon from '@material-ui/icons/Menu';

import CheckinStepper from '../CheckinStepper/CheckinStepper';
import Drawer from '../Drawer/Drawer';
import { ReactComponent as LogoAirGreenland } from '../../assets/img/LogoAirGreenland.svg';
import Text from '../../components/UI/Text/Text';

import { NavigationContext } from '../../context/navigation';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { LanguageContext } from '../../context/language';

const useStyles = makeStyles((theme) => ({
  appBar: {
    border: 0,
    color: '#fff',
    background: theme.palette.primary.main,
    [theme.breakpoints.between('md', 'md')]: {
      background: 'transparent',
    },
    [theme.breakpoints.up('xl')]: {
      background: 'transparent',
    },
  },
  logo: {
    height: 30,
    width: 'auto',
  },
  backgroundColor: {
    backgroundColor: theme.palette.primary.main,
  },
  mobileMenu: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopMenu: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Header = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const { setLanguage } = useContext(LanguageContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [header, setHeader] = useState(true);
  const [langChangedHeader, setLangChangedHeader] = useState(false);

  if ((query.get('disableHeader') === 'Y' || Number(query.get('CTAppEmbedded')) === 1) && header === true) {
    setHeader(false);
  }

  if (query.get('ln') && !langChangedHeader) {
    setLangChangedHeader(true);
    switch (query.get('ln')) {
      case 'da':
        setLanguage('dk');
        break;
      case 'kl':
        setLanguage('gl');
        break;
      case 'en':
        setLanguage('en');
        break;
      default:
        break;
    }
  }

  const {
    step,
  } = useContext(NavigationContext);

  const restartCheckIn = () => {
    history.push('/');
    setDrawerOpen(false);
  };

  let environment;
  if (process.env.REACT_APP_DEPLOY_ENV !== 'production') {
    environment = `${process.env.REACT_APP_DEPLOY_ENV} |`;
  }

  return header && (
  <Box className={classes.backgroundColor} position="relative" zIndex={10}>
    <AppBar className={classes.appBar} position="sticky" variant="outlined">
      <Toolbar variant="regular">
        <LogoAirGreenland className={classes.logo} />
        <Box ml="auto" display="flex" alignItems="center">
          <Box>
            <Typography variant="h3">
              {environment}
            </Typography>
          </Box>
          <Box ml={1} display="flex" alignItems="center" className={classes.mobileMenu}>
            <MenuIcon onClick={() => setDrawerOpen(true)} />
            <Drawer open={drawerOpen} setOpen={setDrawerOpen} restartCheckIn={restartCheckIn} />
          </Box>
          <Box ml={1} display="flex" alignItems="center" className={classes.desktopMenu}>
            <Box ml={1} onClick={restartCheckIn}>
              <Button><Typography color="textSecondary" variant="h3"><Text id="drawer-restart-checkin" /></Typography></Button>
            </Box>
            <Box ml={1}>
              <LanguageSelect />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
    <Box>
      {step > -1 && <Container maxWidth="sm"><CheckinStepper step={step} /></Container>}
    </Box>
  </Box>
  );
};

export default Header;
