import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CheckedInIcon from '@material-ui/icons/CheckCircleOutline';

import Text from '../../../../UI/Text/Text';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    height: 18,
    margin: '0 3px 0 11px',
  },
}));

const AllCheckedInBanner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CheckedInIcon className={classes.icon} />
      <Typography variant="caption"><Text id="flight-card-header-all-checked-in" /></Typography>
    </Box>
  );
};

export default AllCheckedInBanner;
