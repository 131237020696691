import axios from 'axios';
import * as Sentry from '@sentry/react';

// API domain
let domain = 'https://checkin-api.test.gl.objective.se/api';
if (process.env.REACT_APP_BACKEND_API) {
  domain = process.env.REACT_APP_BACKEND_API;
  if (process.env.REACT_APP_BACKEND_VERSION) {
    domain = `${domain}/${process.env.REACT_APP_BACKEND_VERSION}`;
  }
}

const header = (token) => token && { headers: { Authorization: token } };

// Error
// TODO Handle
const handleNetworkError = (errorFunction, error) => {
  Sentry.captureException(new Error('Network error'), error);
  return errorFunction('Network error');
};

const sendErrorToSentryCheck = (errorObject) => {
  if (errorObject.message === 'Free seating please use neutral display.'
  || errorObject.message === 'Free seating please use neutral display'
  || errorObject.message === 'Specific seat requested is not available.'
  || errorObject.message === 'No customers found'
  || errorObject.code === '37'
  ) {
    return false;
  }
  return true;
};

// Amadeus error
const handleAmadeusError = (response, errorFunction) => {
  const { data } = response;
  const { error } = data;
  // Error object
  const errorObject = {
    message: error.text,
    errorType: error.type,
    code: error.code,
  };
  if (sendErrorToSentryCheck(errorObject)) {
    Sentry.captureException(new Error(error.text), (scope) => {
      scope.setExtras({ error: errorObject });
      scope.setTag('message', errorObject.message);
      scope.setTag('code', errorObject.code);
      scope.setTag('errorType', errorObject.errorType);
      return scope;
    });
  }
  return errorFunction(errorObject);
};

// Response
const handleResponse = (response, successFunction, errorFunction, body) => {
  if (response.data && response.data.error && errorFunction) {
    return handleAmadeusError(response, errorFunction);
  }
  return successFunction(response.data, body);
};

// GET
export const get = (path, successFunction, errorFunction, token) => axios
  .get(domain + path, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));

// PATCH
export const patch = (path, body, successFunction, errorFunction, token) => axios
  .patch(domain + path, body, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction, body))
  .catch((error) => handleNetworkError(errorFunction, error));

// POST
export const post = (path, body, successFunction, errorFunction, token) => axios
  .post(domain + path, body, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));
// DELETE
export const del = (path, body, successFunction, errorFunction, token) => axios
  .delete(domain + path, { data: body, headers: { Authorization: token } })
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));
