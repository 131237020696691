import React, { useState, createContext } from 'react';

export const NavigationContext = createContext();

export const NavigationProvider = (props) => {
  const { children } = props;

  const [step, setStep] = useState(-1);
  const [appEmbedded, setAppEmbedded] = useState(0);

  const values = {
    step,
    setStep,
    appEmbedded,
    setAppEmbedded,
  };

  return (
    <NavigationContext.Provider value={values}>
      {children}
    </NavigationContext.Provider>
  );
};
