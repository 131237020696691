import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Text from '../../components/UI/Text/Text';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 0 16px 0',
  },
}));

const steps = [
  {
    key: 'check-in',
    label: <Text id="header-check-in-title" />,
  },
  {
    key: 'summary',
    label: <Text id="header-summery-title" />,
  },
  {
    key: 'boarding-pass',
    label: <Text id="header-boarding-pass-title" />,
  },
];

const CheckinStepper = (props) => {
  const classes = useStyles();

  const {
    step,
  } = props;

  return (
    step >= 0 && (
    <Stepper alternativeLabel activeStep={step} className={classes.root}>
      {steps.map((s) => (
        <Step key={s.key}>
          <StepLabel>{s.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    )
  );
};

CheckinStepper.propTypes = {
  step: PropTypes.number,
};

export default CheckinStepper;
