import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import MuiAccordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import DetailedSummaryInfo from '../../../../UI/DetailedSummaryInfo/DetailedSummaryInfo';
import Text from '../../../../UI/Text/Text';
import { PurposeOfTravelContext } from '../../../../../context/purposeOfTravel';

const Accordion = withStyles({
  root: {
    border: '0px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  header: {
    fontSize: 9,
  },
  name: {
    textTransform: 'capitalize',
    fontSize: 10,
  },
  warning: {
    fontSize: 9,
    color: theme.palette.warning.main,
  },
  desktopExpand: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const DetailedSummary = (props) => {
  const {
    flights,
    expanded,
    expandedBtn,
    setExpanded,
  } = props;

  const {
    selectedPurpose,
    purposeList,
  } = useContext(PurposeOfTravelContext);

  const classes = useStyles();

  const checkinPax = [];
  if (flights) {
    flights.map((flight) => flight.passengers
      .map((passenger) => checkinPax.push(passenger.checkin)));
  }

  let status;
  if (checkinPax && !checkinPax.includes(false)) {
    status = <Typography color="secondary" className={classes.name}><Text id="summary-card-summary-table-status-fully-checked-in" /></Typography>;
  } else if (checkinPax && checkinPax.includes(true)) {
    status = <Typography className={classes.warning}><Text id="summary-card-summary-table-status-partially-checked-in" /></Typography>;
  } else {
    status = <Typography color="error" className={classes.name}><Text id="summary-card-summary-table-status-not-checked-in" /></Typography>;
  }

  return (
    <>
      <Accordion square expanded={expanded} onClick={(() => setExpanded(!expanded))}>
        <AccordionSummary>
          <Box display="flex" flexGrow={1} flexDirection="column">
            <Grid container>
              <Grid item xs={4} sm={4}>
                <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-summary-table-status" /></Typography>
              </Grid>
              <Grid item xs={5} sm={4}>
                <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-summary-table-purpose-of-travel" /></Typography>
              </Grid>
              <Grid item xs={3} sm={4}>
                <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-summary-table-class" /></Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} sm={4}>
                <Typography variant="subtitle1" className={classes.name}>{status}</Typography>
              </Grid>
              <Grid item xs={5} sm={4}>
                <Typography variant="subtitle1" className={classes.name}>
                  {selectedPurpose && purposeList
                    && purposeList.find((x) => x.id === selectedPurpose).purpose}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={4}>
                <Typography variant="subtitle1" className={classes.name}>
                  {flights[0].passengers[0].bookingClass === 'C'
                  || flights[0].passengers[0].bookingClass === 'Z'
                  || flights[0].passengers[0].bookingClass === 'D' ? 'Premium' : 'Economy'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.desktopExpand}>
            {expandedBtn}
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block', padding: '0px' }}>
          {flights && flights.map((flight) => (
            <Fragment key={flight.flightNr + flight.station.from.code}>
              <DetailedSummaryInfo flight={flight} />
            </Fragment>
          ))}
        </AccordionDetails>
        <AccordionActions />
      </Accordion>
    </>
  );
};

DetailedSummary.propTypes = {
  flights: PropTypes.array,
};

export default DetailedSummary;
