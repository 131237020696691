import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getDate } from '../../../../../utils/date';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#E0F4fD',
    padding: 10,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  flightIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0px 5px 0px',
  },
  dashedLine: {
    backgroundColor: '#FFF',
    color: '#FFF',
    border: 'none',
    borderTop: '1px dashed black',
    height: 1,
  },
  header: {
    fontSize: 9,
  },
});

const SummaryHeadCard = (props) => {
  const classes = useStyles();

  const {
    className,
    flights,
  } = props;

  const arrivalStationDate = flights && flights[0].flightTime
  && flights[0].flightTime.scheduledDeparture;
  const departureStationDate = flights && flights[flights.length - 1].flightTime
  && flights[flights.length - 1].flightTime.scheduledArrival;

  return (
    <Box display={{ xs: 'grid', md: 'flex' }} className={clsx(classes.root, className)}>
      <Box flexGrow={1} className={classes.text} textAlign="left">
        <Typography variant="h4">
          {flights && flights.map((flight) => `${flight.station && flight.station.from && flight.station.from.name} - `)}
          {flights && flights[flights.length - 1].station
            && flights[flights.length - 1].station.to.name}
        </Typography>
      </Box>
      <Box minWidth="120px" className={classes.text} textAlign={{ xs: 'left', md: 'right' }}>
        <Typography variant="h6">{`${getDate(arrivalStationDate)} - ${getDate(departureStationDate)}`}</Typography>
      </Box>
    </Box>
  );
};

SummaryHeadCard.propTypes = {
  flights: PropTypes.array,
};

export default SummaryHeadCard;
