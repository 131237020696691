import React, { useState, createContext } from 'react';
import { dictionaryList } from '../languages';

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const { children } = props;

  const defaultLanguage = window.localStorage.getItem('rcml-lang');
  const [language, setLanguage] = useState(defaultLanguage || 'en');

  const provider = {
    language,
    setLanguage,
    dictionary: dictionaryList[language],
    defaultDictionary: dictionaryList.en,
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};
