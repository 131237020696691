/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useContext } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import ErrorMessage from '../../UI/ErrorMessage/ErrorMessage';
import FormFieldList from '../../UI/Form/FormFieldList/FormFieldList';
import Dialog from '../../UI/Dialog/Dialog';
import { PassengerInfoContext } from '../../../context/passengerInfo';
// import Text from '../../UI/Text/Text';
import Button from '../../UI/Button/Button';

export const titlesAvailable = ['MS', 'MR', 'MRS'];

export const gendersAvailable = [
  {
    type: 'M',
    description: 'Male',
  },
  {
    type: 'F',
    description: 'Female',
  },
];

const PassengerInfo = (props) => {
  const {
    passenger, flight, open, setOpen,
  } = props;

  const {
    updatePassengerInfo,
    updatePassengerInfoError, updatePassengerInfoLoading, setUpdatePassengerInfoError,
  } = useContext(PassengerInfoContext);

  const getGenderDescription = (type) => {
    const foundGender = gendersAvailable.find((gender) => gender.type === type);
    return foundGender ? foundGender.description : type;
  };

  const getGenderType = (description) => {
    const foundGender = gendersAvailable.find((gender) => gender.description === description);
    return foundGender ? foundGender.type : description;
  };

  const initialState = useMemo(() => (
    {
      title: passenger.name.title,
      type: passenger.type,
      gender: getGenderDescription(passenger.gender),
    }
  ), [passenger]);

  const [values, setValues] = useState(initialState);

  // Unmount
  useEffect(() => () => {
    setUpdatePassengerInfoError();
  }, [setUpdatePassengerInfoError]);

  const formFields = [
    {
      title: 'Title', value: values.title, items: titlesAvailable, type: 'select', gridSize: 12,
    },
    {
      title: 'Gender', value: values.gender, items: gendersAvailable.map((gender) => gender.description), type: 'select', gridSize: 12,
    },
  ];

  const handleChange = (properties, event) => {
    const { value } = properties;
    if (value === 'Title') {
      setValues({ ...values, title: titlesAvailable[event.target.value] });
    } else if (value === 'Gender') {
      setValues({ ...values, gender: gendersAvailable[event.target.value].description });
    }
  };

  const handleSubmit = () => {
    updatePassengerInfo(passenger, getGenderType(values.gender), values.title, flight.station.from.code).then((res) => {
      if (res && !res.error) {
        setOpen(false);
      }
    });
  };

  let progressBar;
  if (updatePassengerInfoLoading) {
    progressBar = <LinearProgress color="secondary" />;
  }

  let errorMessage;
  if (updatePassengerInfoError) {
    errorMessage = <ErrorMessage error="Error updating booking info" />;
  }

  return (
    <>
      <Dialog
        open={open}
        setOpen={setOpen}
        // title={`${passenger.name.surName} ${passenger.name.firstName}`}
        action={null}
      >
        <Typography variant="h3">{`${passenger.name.surName} ${passenger.name.firstName}`}</Typography>
        <DialogContent>
          <FormFieldList
            values={values}
            formFields={formFields}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          {progressBar}
          {errorMessage}
        </DialogContent>
        <DialogActions>
          <Box m={1} style={{ width: '100%' }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  text={<Typography variant="h4">Cancel</Typography>}
                  action={() => setOpen(false)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Button
                  text={<Typography variant="h4">Update</Typography>}
                  color="secondary"
                  action={() => handleSubmit()}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PassengerInfo;
