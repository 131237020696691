import React, { useState, createContext, useContext } from 'react';

import { BookingContext } from './booking';
import * as api from '../api';

export const SeatMapContext = createContext();

export const SeatMapProvider = (props) => {
  const { children } = props;

  const [seatMap, setSeatMap] = useState();

  const [activePassenger, setActivePassenger] = useState(0);
  const [seatChanged, setSeatChanged] = useState([]);

  const [getFDSeatMapError, setGetFDSeatMapError] = useState();
  const [updateSeatError, setUpdateSeatError] = useState(false);
  const [cancelSeatError, setCancelSeatError] = useState(false);
  const [updateSeatWarning, setUpdateSeatWarning] = useState();

  const [openSeatMapDialog, setOpenSeatMapDialog] = useState(false);
  const [activeFlightInJourneyIndex, setActiveFlightInJourneyIndex] = useState(0);
  const [activeJourney, setActiveJourney] = useState(null);
  const [activeJourneyIndex, setActiveJourneyIndex] = useState(null);

  const {
    booking,
    refetchBooking,
  } = useContext(BookingContext);

  const fetchSeatMap = (flight) => {
    const { passengers } = flight;
    const request = {
      flight: {
        arrivalStation: flight.station.to.code,
        carrier: flight.carrier,
        departureStation: flight.station.from.code,
        flightNr: flight.flightNr,
        scheduledDeparture: flight.flightTime.scheduledDeparture,
      },
      passengers: passengers.map((passenger) => ({
        passengerFlightIdentifier: passenger.passengerFlightIdentifier,
        passengerIdentifier: passenger.passengerIdentifier,
        firstName: passenger.name.firstName,
        surName: passenger.name.surName,
        type: passenger.type,
      })),
    };
    setGetFDSeatMapError();
    setSeatMap();
    return api.post(
      '/dcsstg/getFDSeatMap/seatMap',
      request,
      (res) => setSeatMap(res),
      (error) => setGetFDSeatMapError(error),
      booking.token,
    );
  };

  const clearErrors = () => {
    setGetFDSeatMapError();
    setUpdateSeatError();
    setCancelSeatError();
  };

  const clearSeatMap = () => {
    clearErrors();
    setActivePassenger(0);
    setSeatChanged(false);
    setSeatMap();
    setOpenSeatMapDialog(false);
    setActiveFlightInJourneyIndex(0);
  };

  const fetchNextSeatMap = (nextActiveFlightInJourneyIndex) => {
    clearErrors();
    setActivePassenger(0);
    setSeatChanged(false);
    setSeatMap();
    refetchBooking();
    if (nextActiveFlightInJourneyIndex < activeJourney.length) {
      fetchSeatMap(activeJourney[nextActiveFlightInJourneyIndex]);
      setActiveFlightInJourneyIndex(nextActiveFlightInJourneyIndex);
    } else {
      clearSeatMap();
    }
  };

  const fetchPrivSeatMap = () => {
    clearErrors();
    setActivePassenger(0);
    setSeatChanged(false);
    setSeatMap();
    setActiveJourney();

    if (activeFlightInJourneyIndex >= 1) {
      fetchSeatMap(activeJourney[activeFlightInJourneyIndex - 1]);
      setActiveFlightInJourneyIndex(activeFlightInJourneyIndex - 1);
      refetchBooking();
      const journey = booking
      && booking.allJourneys[activeJourneyIndex];
      setActiveJourney(journey);
    } else {
      clearSeatMap();
    }
  };

  const updateSeat = () => {
    const { passengers } = activeJourney[activeFlightInJourneyIndex];

    let nextActiveFlightInJourneyIndex = activeFlightInJourneyIndex;
    while (activeJourney[nextActiveFlightInJourneyIndex + 1]
      && (activeJourney[activeFlightInJourneyIndex].flightNr
        === activeJourney[nextActiveFlightInJourneyIndex + 1].flightNr)) {
      nextActiveFlightInJourneyIndex += 1;
    }

    let requestSeatChange = false;
    const newSeat = [];
    const request = {
      flights: [{
        arrivalStation: activeJourney[nextActiveFlightInJourneyIndex].station.to.code,
        carrier: activeJourney[activeFlightInJourneyIndex].carrier,
        departureStation: activeJourney[activeFlightInJourneyIndex].station.from.code,
        flightNr: activeJourney[activeFlightInJourneyIndex].flightNr,
        scheduledDeparture: activeJourney[activeFlightInJourneyIndex].flightTime.scheduledDeparture,
        passengers: passengers.filter((passenger, index) => {
          newSeat[passenger.passengerFlightIdentifier] = seatChanged[index];
          return seatChanged[index] !== '';
        }).map((passenger) => {
          if (passenger.seat !== '') {
            requestSeatChange = true;
          }
          return ({
            passengerFlightIdentifier: passenger.passengerFlightIdentifier,
            seat: newSeat[passenger.passengerFlightIdentifier],
          });
        }),
      }],
      pnr: passengers[0].pnr,
      station: activeJourney[0].station.from.code,
      requestSeatChange,
    };

    setUpdateSeatError();
    return api.post(
      '/dcsstg/allocateSeat/allocate',
      request,
      (res) => {
        fetchNextSeatMap(nextActiveFlightInJourneyIndex + 1);
        setUpdateSeatWarning(res.warning);
        return res;
      },
      setUpdateSeatError,
      booking.token,
    );
  };

  // const cancelSeat = (passengerReq, activeJourneyIndexReq) => {
  //   const request = {
  //     flights: [{
  //       arrivalStation: activeJourney[activeJourneyIndexReq].station.to.code,
  //       carrier: activeJourney[activeJourneyIndexReq].carrier,
  //       departureStation: activeJourney[activeJourneyIndexReq].station.from.code,
  //       flightNr: activeJourney[activeJourneyIndexReq].flightNr,
  //       scheduledDeparture: activeJourney[activeJourneyIndexReq].flightTime.scheduledDeparture,
  //       passengers: passengerReq.map((passenger) => ({
  //         passengerFlightIdentifier: passenger.passengerFlightIdentifier,
  //         seat: passenger.seat,
  //       })),
  //     }],
  //     pnr: passengerReq[0].pnr,
  //     station: activeJourney[0].station.from.code,
  //   };

  //   setCancelSeatError();
  //   return api.del(
  //     '/dcsstg/deallocationSeat/deallocate',
  //     request,
  //     () => fetchBookingByPNR(passengerReq[0].pnr),
  //     setCancelSeatError,
  //     booking.token,
  //   );
  // };

  const setOpenSeatMap = (journeyIndex) => {
    const journey = booking
     && booking.allJourneys[journeyIndex];

    setActiveJourney(journey);
    setActiveJourneyIndex(journeyIndex);
    fetchSeatMap(journey[0]);
    setOpenSeatMapDialog(true);
  };

  const values = {
    openSeatMapDialog,
    setOpenSeatMap,
    getFDSeatMapError,
    seatMap,
    clearErrors,
    clearSeatMap,
    fetchPrivSeatMap,
    activePassenger,
    setActivePassenger,
    setUpdateSeatError,
    seatChanged,
    updateSeatWarning,
    cancelSeatError,
    setSeatChanged,
    // cancelSeat,
    setUpdateSeatWarning,
    updateSeatError,
    activeJourney,
    setActiveFlightInJourneyIndex,
    updateSeat,
    activeFlightInJourneyIndex,
  };

  return (
    <SeatMapContext.Provider value={values}>
      {children}
    </SeatMapContext.Provider>
  );
};
