import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';

// Styles
const useStyles = makeStyles((theme) => ({
  text: {
    textTransform: 'initial',
    color: (props) => props.color || 'white',
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  fullWidth: {
    width: '100%',
  },
}));

const Button = (props) => {
  const classes = useStyles(props);

  // Props
  const {
    action, text, variant, link, icon, error, fullWidth, component,
  } = props;

  // Action
  let onClick;
  let buttonProps = props;
  if (action) {
    onClick = () => action();
    buttonProps = { ...buttonProps, action: null };
  }

  // Change classes
  let className;
  if (variant) {
    className = classes.text;
  } else if (icon) {
    className = classes.icon;
  }

  let fullWidthStyle;
  if (fullWidth) {
    fullWidthStyle = classes.fullWidth;
  }

  return (
    <MaterialButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
      className={clsx(
        error && classes.error,
        className, fullWidthStyle,
      )}
      variant={variant || 'contained'}
      component={link ? Link : component || undefined}
      to={link || '#'}
      onClick={onClick}
    >
      {text}
    </MaterialButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  action: PropTypes.func,
  support: PropTypes.bool,
  error: PropTypes.bool,
  smallText: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;
