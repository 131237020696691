import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Select from '../../components/UI/Select/Select';
import { languages } from '../../languages';
import { LanguageContext } from '../../context/language';

const useStyles = makeStyles(() => ({
  select: {
    width: 214,
  },
}));
const LanguageSelect = () => {
  const classes = useStyles();

  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <Select
      justifycontenttext="center"
      value={language}
      className={classes.select}
      items={languages}
      onChange={(event) => setLanguage(event.target.value)}
    />
  );
};

export default LanguageSelect;
