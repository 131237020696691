import React, { useContext, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SeatMapCard from '../../components/Checkin/Seatmap/SeatMapCard';
import PassengerCard from '../../components/Checkin/Seatmap/PassengerCard/PassengerCard';
import Text from '../../components/UI/Text/Text';
import { SeatMapContext } from '../../context/seatMap';
import Notification from '../../components/UI/Notification/Notification';
import Loading from '../../components/UI/Loading/Loading';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingLeft: '0px',
    paddingRight: '0px',
    minHeight: '400px',
    paddingTop: '0px !important',
    paddingBottom: '0px',
    background: 'transparent',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      overflowY: 'clip',
    },
    [theme.breakpoints.down('sm')]: {
      background: '#fff',
    },
  },
  boxContainer: {
    background: '#fff',
    height: '90vh',
    maxHeight: '1000px',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      overflowY: 'unset',
    },
  },
  rectangle: {
    border: 'solid 2px #1cb3ef',
    padding: 5,
    paddingLeft: 10,
    color: '#000',
    paddingRight: 10,
    textAlign: 'center',
    width: 'inherit',
  },
  active: {
    background: theme.palette.secondary.main,
    color: '#fff',
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const SeatInfoContainer = () => {
  const [seatUpdateSuccess, setSeatUpdateSuccess] = useState(false);
  const classes = useStyles();

  const {
    clearSeatMap,
    updateSeatError,
    openSeatMapDialog,
    seatMap,
    setUpdateSeatError,
    activeJourney,
    activeFlightInJourneyIndex,
    activePassenger,
    seatChanged,
    setActivePassenger,
    getFDSeatMapError,
    setSeatChanged,
    updateSeat,
    fetchPrivSeatMap,
    // setUpdateSeatWarning,
    // updateSeatWarning,
  } = useContext(SeatMapContext);

  let errorNotification;
  if (updateSeatError && !seatUpdateSuccess) {
    errorNotification = <Notification onClose={() => (setUpdateSeatError())} severity="error" message={<Text id="seat-map-notification-error" />} />;
  }
  // let warningNotification;
  // if (updateSeatWarning && !seatUpdateSuccess) {
  // eslint-disable-next-line max-len
  //   warningNotification = <Notification onClose={() => (setUpdateSeatWarning())} severity="warning" message={updateSeatWarning.text} />;
  // }

  return (
    <>
      {seatUpdateSuccess
        && (
          <Notification
            onClose={() => (setSeatUpdateSuccess())}
            severity="success"
            message={(
              <Box minWidth="200px">
                <Text id="seat-map-notification-success" />
              </Box>
            )}
          />
        )}
      {errorNotification}
      <Dialog
        fullWidth
        maxWidth="md"
        PaperComponent={Box}
        open={openSeatMapDialog}
        onClose={() => clearSeatMap()}
      >
        {((seatMap && activeJourney[activeFlightInJourneyIndex]) || getFDSeatMapError) ? (
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PassengerCard
                  activeFlightInJourneyIndex={activeFlightInJourneyIndex}
                  activeJourney={activeJourney}
                  activePassenger={activePassenger}
                  setActivePassenger={setActivePassenger}
                  newSeat={seatChanged}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.boxContainer}>
                <Box py={{ xs: 0, sm: 3 }} px={3} style={{ background: '#fff' }}>
                  <Typography variant="h5" color="secondary">{`Flight ${activeFlightInJourneyIndex + 1} out of ${activeJourney.length}`}</Typography>
                  <Typography variant="h2">{` ${activeJourney[activeFlightInJourneyIndex].station.from.name} - ${activeJourney[activeFlightInJourneyIndex].station.to.name}`}</Typography>
                  <Typography variant="h4">{`${activeJourney[activeFlightInJourneyIndex].carrier}${activeJourney[activeFlightInJourneyIndex].flightNr}`}</Typography>
                </Box>
                <Box pt={4} height="100%" style={{ background: '#fff' }}>
                  <SeatMapCard
                    activePassenger={activePassenger}
                    setSeatChanged={setSeatChanged}
                    updateSeat={updateSeat}
                    getFDSeatMapError={getFDSeatMapError}
                    fetchPrivSeatMap={fetchPrivSeatMap}
                    setSeatUpdateSuccess={setSeatUpdateSuccess}
                    flight={activeJourney[activeFlightInJourneyIndex]}
                    seatmap={seatMap}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )
          : (
            <DialogContent>
              <Box mx="auto" height="350px" maxWidth="350px" style={{ background: '#fff' }}>
                <Loading />
              </Box>
            </DialogContent>
          )}

      </Dialog>
    </>
  );
};

export default SeatInfoContainer;
