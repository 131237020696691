import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SelectField from '../../SelectField/SelectField';
import Button from '../../Button/Button';
import InputField from '../../InputField/InputField';
import SectionTitle from '../../SectionTitle/SectionTitle';
import MultiValueSearchField from '../../MultiField/MultiValueSearchField/MultiValueSearchField';

// Styles
const useStyles = makeStyles((theme) => ({
  commentBox: {
    height: theme.spacing(22.5),
  },
  title: {
    margin: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const FormFieldList = (props) => {
  const classes = useStyles();
  // Props
  const {
    formFields,
    handleChange,
    handleDiscard,
    handleSubmit,
    isChanged,
    values,
    setValues,
    index,
  } = props;

  const writeField = (field) => {
    const value = field.title;
    const properties = { value, index };
    let extraTitle;
    if (field.extraTitle) {
      extraTitle = (
        <Box pb={1}>
          <SectionTitle text={field.extraTitle} />
        </Box>
      );
    }
    return (
      <Grid item xs={field.gridSize}>
        <Box mb={1}>
          {extraTitle}
          {field.value
            ? (
              <InputField
                required={field.required}
                label={field.title}
                key={field.title}
                value={field.value}
                onChange={(e) => handleChange(properties, e)}
                id="outlined-required"
                variant="outlined"
                endAdornment={field.suffix}
                disabled={field.disabled}
                fullWidth
              />
            )
            : (
              <InputField
                required={field.required}
                label={field.title}
                key={field.title}
                value={field.value}
                onChange={(e) => handleChange(properties, e)}
                id="outlined-required"
                variant="outlined"
                fullWidth
                endAdornment={field.suffix}
                disabled={field.disabled}
                error
              />
            )}
        </Box>
      </Grid>
    );
  };
  const multipleValueSearchField = (field) => (
    <Grid item xs={field.gridSize}>
      <Box mb={1}>
        <MultiValueSearchField
          label={field.title}
          key={field.title}
          items={field.items}
          value={field.value}
          id="outlined-required"
          variant="outlined"
          endAdornment={field.suffix}
          fullWidth
          setValues={setValues}
        />
      </Box>
    </Grid>
  );

  const content = (
    formFields.map((field) => {
      const value = field.title;
      const properties = { value, index };
      let extraTitle;
      if (field.extraTitle) {
        extraTitle = (
          <Box pb={1}>
            <SectionTitle text={field.extraTitle} />
          </Box>
        );
      }

      if (field.type === 'number') {
        return (
          <Grid item xs={field.gridSize}>
            <Box mb={1}>
              {extraTitle}
              <TextField
                key={field.title}
                label={field.title}
                fullWidth
                onChange={(e) => handleChange(properties, e)}
                value={field.value}
                type="number"
                required={field.required}
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-number"
                variant="outlined"
                disabled={field.disabled}
                inputProps={{
                  endAdornment: field.suffix,
                }}
              />
            </Box>
          </Grid>
        );
      }
      if (field.type === 'select') {
        return (
          <Grid item xs={field.gridSize} key={field.title}>
            <Box mb={1}>
              {extraTitle}
              {field.value
                ? (
                  <SelectField
                    required={field.required}
                    key={field.title}
                    label={field.title}
                    fullWidth
                    onChange={(e) => handleChange(properties, e)}
                    items={field.items}
                    value={field.items.indexOf(field.value)}
                    id="outlined-required"
                    variant="outlined"
                    disabled={field.disabled}
                  />
                )
                : (
                  <SelectField
                    required={field.required}
                    key={field.title}
                    label={field.title}
                    fullWidth
                    onChange={(e) => handleChange(properties, e)}
                    items={field.items}
                    value={field.items.indexOf(field.value)}
                    id="outlined-required"
                    variant="outlined"
                    error
                    disabled={field.disabled}
                  />
                )}
            </Box>
          </Grid>
        );
      } if (field.type === 'multipleValueSearchField') {
        return (
          <>
            {multipleValueSearchField(field)}
          </>
        );
      } if (field.type === 'writeField') {
        return (
          <>
            {writeField(field)}
          </>
        );
      } if (field.type === 'writeName') {
        if (field.title === 'firstName') {
          return (
            <Grid item xs={field.gridSize}>
              <Box mb={1}>
                {extraTitle}
                {field.value
                  ? (
                    <InputField
                      required={field.required}
                      label="First name"
                      key={field.title}
                      value={values.firstName}
                      onChange={(e) => handleChange(properties, e)}
                      fullWidth
                      id="outlined-disabled"
                      variant="outlined"
                      helperText="Disabled"
                      disabled
                    />
                  )
                  : (
                    <InputField
                      required={field.required}
                      label="First name"
                      key={field.title}
                      value={values.firstName}
                      onChange={(e) => handleChange(properties, e)}
                      fullWidth
                      id="outlined-disabled"
                      variant="outlined"
                      helperText="Disabled"
                      disabled
                      error
                    />
                  )}
              </Box>
            </Grid>
          );
        } if (field.title === 'surName') {
          return (
            <Grid item xs={field.gridSize}>
              <Box mb={1}>
                {extraTitle}
                {field.value ? (
                  <InputField
                    required={field.required}
                    label="Surname"
                    key={field.title}
                    value={values.surName}
                    onChange={(e) => handleChange(properties, e)}
                    fullWidth
                    disabled
                    id="outlined-disabled"
                    variant="outlined"
                  />
                )
                  : (
                    <InputField
                      required={field.required}
                      label="Surname"
                      key={field.title}
                      value={values.surName}
                      onChange={(e) => handleChange(properties, e)}
                      fullWidth
                      disabled
                      id="outlined-disabled"
                      variant="outlined"
                    />
                  )}
              </Box>
            </Grid>
          );
        }
      } else if (field.type && field.type.toUpperCase() === 'COMMENT') {
        return (
          <Grid item xs={field.gridSize} className={classes.commentBox}>
            <Box mb={1}>
              {extraTitle}
              { field.value
                ? (
                  <InputField
                    required={field.required}
                    label={field.title}
                    key={field.title}
                    value={field.value}
                    onChange={(e) => handleChange(properties, e)}
                    fullWidth
                    multiline
                    rows={6}
                    rowsMax={6}
                    id="outlined-required"
                    variant="outlined"
                    disableUnderline
                  />
                )
                : (
                  <InputField
                    required={field.required}
                    label={field.title}
                    key={field.title}
                    value={field.value}
                    onChange={(e) => handleChange(properties, e)}
                    fullWidth
                    multiline
                    rows={6}
                    rowsMax={6}
                    id="outlined-required"
                    variant="outlined"
                    disableUnderline
                    error
                  />
                )}
            </Box>
          </Grid>
        );
      }
      return null;
    })
  );

  const submitButtons = (
    <Box pt={1}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button
            fullWidth
            action={handleDiscard}
            text={<Typography variant="h4">Discard</Typography>}
            id="Discard"
          />
        </Grid>
        <Grid item xs={8}>
          <Button
            fullWidth
            action={handleSubmit}
            text={<Typography variant="h4">Save Changes</Typography>}
            color="primary"
            id="Save Changes"
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <List>
      <Grid container spacing={1} alignItems="flex-end">
        {content}
      </Grid>
      {isChanged && submitButtons}
    </List>
  );
};

FormFieldList.propTypes = {
  isChanged: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default FormFieldList;
