import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const InputField = (props) => {
  // Props
  const {
    id,
    startAdornment,
    endAdornment,
    variant,
    dense,
    error,
    helperText,
    placeholder,
    label,
    disabled,
    ...rest
  } = props;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <TextField
      {...rest}
      id={id}
      label={label}
      autoComplete="off"
      variant={variant || 'outlined'}
      margin={dense && 'dense'}
      placeholder={placeholder || ''}
      error={error}
      helperText={helperText}
      inputProps={{
        startAdornment,
        endAdornment,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      disabled={disabled}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

InputField.propTypes = {
  id: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disableUnderline: PropTypes.bool,
  variant: PropTypes.string,
  dense: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  error: PropTypes.bool,
};

export default InputField;
