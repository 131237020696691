import React, { useState, useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
  alert: {
    width: '100%',
    borderRadius: '0px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Notification = (props) => {
  const classes = useStyles();
  const {
    message,
    severity,
    onClose,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    open && (
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
      >
        <Alert className={classes.alert} severity={severity || 'info'}>
          <Typography variant="h5" className={classes.title}>{severity}</Typography>
          <Typography variant="body2">{message}</Typography>
        </Alert>
      </Snackbar>
    )
  );
};

export default Notification;
