import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Available } from '../../../../../../assets/img/seat-available.svg';
import { ReactComponent as Occupied } from '../../../../../../assets/img/seat-occupied.svg';
import { ReactComponent as Selected } from '../../../../../../assets/img/seat-selected.svg';

// import SeatInfoModal from './SeatInfoModal/SeatInfoModal';

// Styles
const useStyles = makeStyles((theme) => ({
  seat: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    fontSize: 20,
    maxWidth: '50px',
    border: 'none',

    '&:last-child': {
      marginRight: 0,
    },

    [theme.breakpoints.only('xs')]: {
      marginRight: 4,
      fontSize: 16,
    },
  },
  free: {
    border: 'none',
    color: 'white',
  },
  occupied: {
    border: 'none',
    color: 'black',
  },
  active: {
    color: 'black',
    '-webkit-box-sizing': 'border-box',
    '-moz-box-sizing': 'border-box',
    boxSizing: 'border-box',
    fontWeight: 'bold',
  },
  unavailable: {
    border: 'none',
    color: 'black',
  },
  icon: {
    height: '100%',
    width: '100%',
  },
  selectedSeat: {
    backgroundColor: '#FFF',
    border: '1px solid #000',
    '-webkit-box-sizing': 'border-box',
    '-moz-box-sizing': 'border-box',
    boxSizing: 'border-box',
  },
  textSelectedSeat: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    display: 'flex',
    placeContent: 'center',
    fontSize: '14px',
    placeItems: 'center',
  },
}));

// Seat statuses
const status = Object.freeze({
  occupied: 'O',
  occupied_L: 'L', // not in amadeus DOC seams to be same as O
  protected: 'P',
  restricted: 'R',
  crewRest: 'W',
  broken: 'K',
  courtesyBlocked: 'H',
});

// Seat types
const types = Object.freeze({
  toilet: 'LA',
  extraLeg: 'L',
  jumpSeat: 'JP',
  galley: 'GN',
});

const SeatCol = (props) => {
  const classes = useStyles();

  // Props
  const {
    selectedSeats, seatNr, selectSeat, seatStatus, seatTypes, initialSeats,
  } = props;

  // Check seat type
  const checkSeatType = (seatType) => seatTypes.find((type) => seatType === type.code);

  // Set active seat
  let activeSeat;
  selectedSeats.forEach((selected) => {
    if (seatNr === selected.seat) {
      activeSeat = selected;
    }
  });

  // Set seat class and determine content
  let seatContent; let seatClass; let
    button;
  if (activeSeat) {
    seatClass = classes.active;
    seatContent = (
      <Box textAlign="center" className={classes.icon}>
        <Box position="relative" display="flex">
          <Selected className={classes.icon} />
          <Box className={classes.textSelectedSeat}>
            <Typography variant="inherit" color="textSecondary">{activeSeat.showSeat}</Typography>
          </Box>
        </Box>
      </Box>
    );
  } else if (initialSeats && initialSeats.includes(seatNr)) {
    button = true;
    seatClass = classes.free;
    seatContent = <Available className={classes.icon} />;
  } else {
    // Determine seat status
    switch (seatStatus.code) {
      case status.occupied_L:
      case status.occupied:
        seatClass = classes.occupied;
        seatContent = <Occupied className={classes.icon} />;
        break;
      case status.protected:
      case status.restricted:
      case status.crewRest:
      case status.broken:
      case status.courtesyBlocked:
        seatClass = classes.unavailable;
        seatContent = <Occupied className={classes.icon} />;
        break;
      default:
        button = true;
        seatClass = classes.free;
        seatContent = <Available className={classes.icon} />;
        if (checkSeatType(types.extraLeg)) {
          // seatContent = <ExtraLegIcon className={classes.icon} />;
        } else if (checkSeatType(types.toilet)) {
          seatContent = <></>;
          seatClass = classes.free;
        } else if (checkSeatType(types.galley)) {
          seatContent = <></>;
          seatClass = classes.free;
        } else if (checkSeatType(types.jumpSeat)) {
          // seatContent = <JumpSeatIcon className={classes.icon} />;
        }
    }
  }

  // Content
  let content;
  if (button && selectSeat && !checkSeatType(types.toilet) && !checkSeatType(types.galley)) {
    content = (
      <Grid
        className={clsx(classes.seat, seatClass)}
        onClick={() => selectSeat(seatNr)}
      >
        {seatContent}
      </Grid>
    );
  } else {
    content = (
      <>
        <Grid className={clsx(classes.seat, seatClass)} onClick={null}>
          {seatContent}
        </Grid>
      </>
    );
  }

  return content;
};

SeatCol.propTypes = {
  seat: PropTypes.object,
  selectedSeats: PropTypes.array,
  selectSeat: PropTypes.func,
  seatNr: PropTypes.string,
  seatCol: PropTypes.string,
  seatRow: PropTypes.number,
  error: PropTypes.string,
  initialSeats: PropTypes.array,
  passenger: PropTypes.object,
  flight: PropTypes.object,
};

export default SeatCol;
