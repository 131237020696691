import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: (props) => props.colMaxWidth,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  exitText: {
    color: theme.palette.primary.main,
    fontFamily: 'Lato-Regular',
    textTransform: 'uppercase',
    fontSize: 16,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
  },
}));

const SeatRowExit = (props) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  // Props
  const { height } = props;

  // Row margin
  let rowMargin = 4;
  if (matches) {
    rowMargin = 8;
  }

  // Row styles
  const rowStyles = {
    height,
    marginBottom: rowMargin,
  };

  // Left door
  const leftDoor = {
    width: 2 * rowMargin,
    marginLeft: -3 * rowMargin,
    marginRight: rowMargin,
  };

  // Right door
  const rightDoor = {
    width: 2 * rowMargin,
    marginRight: -3 * rowMargin,
    marginLeft: rowMargin,
  };

  // Edit text
  const exitText = <Typography className={classes.exitText}>Exit</Typography>;

  return (
    <Grid className={classes.row} style={rowStyles}>
      <Grid className={classes.grid}>
        <Grid style={leftDoor} />
        {exitText}
      </Grid>
      <Grid className={classes.grid}>
        {exitText}
        <Grid style={rightDoor} />
      </Grid>
    </Grid>
  );
};

SeatRowExit.propTypes = {
  height: PropTypes.string,
};

export default SeatRowExit;
