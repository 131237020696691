import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import packageJson from '../package.json';

if (process.env.REACT_APP_DEPLOY_ENV === 'production' && window.location.host === 'checkin.airgreenland.com') {
  Sentry.init({
    environment: process.env.REACT_APP_DEPLOY_ENV || 'test',
    release: `${packageJson.name}@${packageJson.version}`,
    dsn: 'https://966e64b03f0f4c2692e0867737ebba83@o172762.ingest.sentry.io/5627887',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const startApp = () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root'),
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
