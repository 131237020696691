import React, { useContext } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useHistory } from 'react-router-dom';
import { wwwDir } from '../../utils/cordovaGlobals';
import clubTimmisaButton from '../../assets/img/clubTimmisaButton.png';
import Text from '../UI/Text/Text';
import { NavigationContext } from '../../context/navigation';

const BoardingPassWallet = () => {
  const { appEmbedded } = useContext(NavigationContext);
  const history = useHistory();

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const goToTimmisa = () => {
    if (appEmbedded) {
      history.push('/?didCompleteCheckin=true');
    } else {
      openInNewTab('https://www.airgreenland.dk/club-timmisa');
    }
  };

  return (
    <Box my={5}>
      <Typography variant="h2"><Text id="boarding-pass-title-timmisa" /></Typography>
      <Typography><Text id="boarding-pass-timissa-banner" /></Typography>
      <ButtonBase
        fullWidth
        color="secondary"
        variant="contained"
        onClick={(() => goToTimmisa())}
      >
        <img src={`${wwwDir}${clubTimmisaButton}`} alt="Club Timissa Button" />
      </ButtonBase>
    </Box>
  );
};

BoardingPassWallet.propTypes = {};

export default BoardingPassWallet;
