import React, { useState, createContext, useContext } from 'react';
import * as api from '../api';
import { BookingContext } from './booking';

export const PassengerInfoContext = createContext();

export const PassengerInfoProvider = (props) => {
  const { children } = props;

  const [updatePassengerInfoLoading, setUpdatePassengerInfoLoading] = useState(false);
  const [updatePassengerInfoError, setUpdatePassengerInfoError] = useState();

  const {
    refetchBooking, booking,
  } = useContext(BookingContext);

  const updatePassengerInfo = (passenger, gender, title, station) => {
    setUpdatePassengerInfoError();
    setUpdatePassengerInfoLoading(true);
    return api.patch(
      '/dcscpr/editCPR/passengerInfo', {
        newPassenger: {
          firstName: passenger.name.firstName,
          surName: passenger.name.surName,
          title,
          type: passenger.type,
          gender,
          passengerIdentifier: passenger.passengerIdentifier,
          passengerFlightIdentifier: passenger.passengerFlightIdentifier,
        },
        passenger: {
          firstName: passenger.name.firstName,
          surName: passenger.name.surName,
          title: passenger.name.title,
          gender: passenger.gender,
          type: passenger.type,
          passengerIdentifier: passenger.passengerIdentifier,
          passengerFlightIdentifier: passenger.passengerFlightIdentifier,
        },
        pnr: passenger.pnr,
        station,
      },
      (res) => {
        setUpdatePassengerInfoLoading(false);
        refetchBooking();
        return res;
      },
      setUpdatePassengerInfoError,
      booking.token,
      setUpdatePassengerInfoLoading(false),
    );
  };

  const provider = {
    updatePassengerInfo,
    updatePassengerInfoLoading,
    updatePassengerInfoError,
    setUpdatePassengerInfoError,
  };

  return (
    <PassengerInfoContext.Provider value={provider}>
      {children}
    </PassengerInfoContext.Provider>
  );
};
