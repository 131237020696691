import React, { useState, createContext, useContext } from 'react';

import * as Sentry from '@sentry/react';
import { BookingContext } from './booking';
import * as api from '../api';

export const CheckinContext = createContext();

export const CheckinProvider = (props) => {
  const { children } = props;

  const [checkinError, setCheckinError] = useState();
  const [checkinWarning, setCheckinWarning] = useState();
  const [cancelCheckinError, setCancelCheckinError] = useState();
  const [promptsQuestion, setPromptsQuestion] = useState();

  const {
    booking,
  } = useContext(BookingContext);

  const checkin = (activeJourneyIndex, prompts) => {
    const activeJourney = booking
      && booking.allJourneys[activeJourneyIndex];

    const { pnr } = activeJourney[0].passengers[0];

    const request = {
      station: activeJourney[0].station.from.code,
      pnr,
      overrideETicket: true,
      overridePrompts: true,
      booking: activeJourney.map((flight) => ({
        ...flight,
        // eslint-disable-next-line max-len
        passengers: flight.passengers,
      })),
    };

    if (prompts) {
      request.prompts = [prompts];
    }

    setCheckinError();
    return api.post(
      '/dcsacc/initiateCustomerAcceptance/checkIn',
      request,
      (res) => {
        if (res.prompts && res.prompts.length > 0) {
          if (promptsQuestion) {
            Sentry.captureException(`Prompts error ${JSON.stringify(promptsQuestion)}`);
          }
          setPromptsQuestion(res.prompts);
          return checkin(activeJourneyIndex, {
            indicator: 'PPY',
            action: res.prompts[0].action,
          });
        }
        if (res.warning && res.warning.code !== '17626') {
          setCheckinWarning(res.warning);
          console.log(`Amadeus checkin warning for PNR ${request.pnr}: ${res.warning}`);
        }
        if (res.error && res.error) {
          console.log(`Amadeus checkin error for PNR ${request.pnr}: ${res.error}`);
        }
        return res;
      },
      setCheckinError,
      booking.token,
    );
  };

  const cancelCheckin = (activeJourneyIndex) => {
    const activeJourney = booking
      && booking.allJourneys[activeJourneyIndex];

    const { pnr } = activeJourney[0].passengers[0];
    const request = {
      pnr,
      carrier: activeJourney[0].carrier,
      flightNr: activeJourney[0].flightNr,
      scheduledDeparture: activeJourney[0].flightTime.scheduledDeparture,
      station: activeJourney[0].station.from.code,
      statusCode: 'CNA',
      reasonCode: 'CR',
      passengers: activeJourney[0].passengers.map((passenger) => ({
        passengerFlightIdentifier: passenger.passengerFlightIdentifier,
        passengerIdentifier: passenger.passengerIdentifier,
        surName: passenger.name.surName,
        type: passenger.type,
      })),
    };
    setCancelCheckinError();
    return api.post(
      '/dcsacc/initiateCancelCustAcceptance/cancel',
      request,
      (res) => res,
      setCancelCheckinError,
      booking.token,
    );
  };

  const clearErrors = () => {
    setCheckinError();
    setCancelCheckinError();
  };
  const clearWarnings = () => {
    setCheckinWarning();
  };

  const values = {
    checkin,
    cancelCheckin,
    checkinError,
    checkinWarning,
    cancelCheckinError,
    clearErrors,
    clearWarnings,
    promptsQuestion,
  };

  return (
    <CheckinContext.Provider value={values}>
      {children}
    </CheckinContext.Provider>
  );
};
