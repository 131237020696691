import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Styles
const useStyles = makeStyles((theme) => ({
  empty: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'white',
    marginRight: 8,
    maxWidth: '50px',

    '&:last-child': {
      marginRight: 0,
    },

    [theme.breakpoints.only('xs')]: {
      marginRight: 4,
    },
  },
}));

const EmptyCol = () => {
  const classes = useStyles();
  return <Grid className={classes.empty} />;
};

export default EmptyCol;
