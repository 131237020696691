import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import { PurposeOfTravelContext } from '../../context/purposeOfTravel';
import Navigation from '../../hoc/Navigation/Navigation';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';
import Select from '../../components/UI/Select/Select';
import PreviewFlightInfo from '../../components/UI/PreviewFlightInfo/PreviewFlightInfo';
import Loading from '../../components/UI/Loading/Loading';
import Notification from '../../components/UI/Notification/Notification';

const useStyles = makeStyles(() => ({
  select: {
    width: '343px',
    height: '52px',
    margin: '8px 0px 16px',
    padding: '9px 14px 10px 12px',
    borderRadius: '1px',
    border: 'solid 1px #b0b5cb',
    backgroundColor: '#ffffff',
  },
}));

const PurposeOfTravel = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loadingUpdatePurpose, setLoadingUpdatePurpose] = useState(false);

  const {
    setStep,
  } = useContext(NavigationContext);

  const {
    purposeListForSelector,
    selectedPurpose,
    setSelectedPurpose,
    purposeListError,
    addPurposeOfTravel,
    initSelectedPurpose,
    setInitSelectedPurpose,
  } = useContext(PurposeOfTravelContext);

  const {
    booking,
  } = useContext(BookingContext);

  useEffect(() => {
    if (!booking) {
      history.push(routes.BOOKING_SEARCH_NO_BOOKING);
    }
  }, [booking, history]);

  useEffect(() => {
    setStep(-1);
  }, [setStep]);

  const handelNext = () => {
    setLoadingUpdatePurpose(true);
    if (selectedPurpose !== initSelectedPurpose) {
      addPurposeOfTravel().then(() => {
        setInitSelectedPurpose(selectedPurpose);
        history.push(routes.CHECK_IN);
      });
    } else if (selectedPurpose !== '') {
      history.push(routes.CHECK_IN);
    }
    setLoadingUpdatePurpose(false);
  };

  useEffect(() => {
    handelNext();
  }, []);

  let errorNotification;
  if (purposeListError) {
    errorNotification = <Notification severity="error" message={<Text id="booking-search-error" />} />;
  }

  return (
    <>
      {!purposeListForSelector || loadingUpdatePurpose ? (
        <Loading />
      ) : (
        <Container maxWidth="md">
          <Box mt={2}>
            <Typography variant="h2"><Text id="purpose-of-travel-title" /></Typography>
            <Typography><Text id="purpose-of-travel-text" /></Typography>
            <Box my={1}>
              <Typography variant="h3"><Text id="purpose-of-travel-title-select" /></Typography>
              {purposeListForSelector && (
              <Select
                justifycontenttext="left"
                value={selectedPurpose}
                className={classes.select}
                items={purposeListForSelector}
                onChange={(event) => setSelectedPurpose(event.target.value)}
              />
              )}
            </Box>

            <Typography><Text id="purpose-of-travel-apply-to-journeys" /></Typography>
            <Box my={1}>
              {booking && booking.allJourneys.map((journey) => (
                <PreviewFlightInfo
                  journey={journey}
                  key={journey[0].flightNr + journey[0].station.from.code}
                />
              ))}
            </Box>

            <Box my={5} pb={5}>
              <Container maxWidth="sm">
                <Navigation
                  backText={<Text id="footer-back-button" />}
                  nextText={<Text id="footer-continue-button" />}
                  backRoute={routes.DANGEROUS_GOODS}
                  onClickNext={handelNext}
                  disableNext={!selectedPurpose}
                  postMessage="/CHECK_IN"
                />
              </Container>
            </Box>
            {errorNotification}
          </Box>
        </Container>
      )}
    </>
  );
};

export default PurposeOfTravel;
