import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    minHeight: 80,
    padding: 25,
    margin: '5px',
    width: 'calc(100% - 10px)',
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      margin: 0,
      marginBottom: '5px',
    },
  },
}));

const Card = (props) => {
  const classes = useStyles();

  const {
    children,
    icon,
    disabled,
    onClick,
  } = props;

  return (
    <ButtonBase
      className={classes.button}
      focusRipple
      disabled={disabled}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {icon}
        <Box mt={1}>
          <Typography
            variant="inherit"
            color="textSecondary"
          >
            {children}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

Card.propTypes = {
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Card;
