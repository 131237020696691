import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FlightRoute from '../../../UI/FlightRoute/FlightRoute';

const useStyles = makeStyles({
  root: {
    padding: 1,
    width: '100%',
  },
});

const BoardingPassCard = (props) => {
  const {
    flight,
  } = props;
  const classes = useStyles();

  return (
    <FlightRoute
      scheduledDeparture={flight.flightTime.scheduledDeparture}
      scheduledArrival={flight.flightTime.scheduledArrival}
      departureTimeZone={flight.station.from.timeZone}
      arrivalTimeZone={flight.station.to.timeZone}
      className={classes.root}
      flightNumber={flight.carrier + flight.flightNr}
      departureStationCode={
        <Typography variant="h4">{(flight.station && flight.station.from) ? flight.station.from.code : 'N/A'}</Typography>
      }
      departureStationName={(flight.station && flight.station.from) ? flight.station.from.name : 'N/A'}
      arrivalStationCode={
        <Typography variant="h4">{(flight.station && flight.station.to) ? flight.station.to.code : 'N/A'}</Typography>
      }
      arrivalStationName={(flight.station && flight.station.to) ? flight.station.to.name : 'N/A'}
    />
  );
};

BoardingPassCard.propTypes = {
  flight: PropTypes.object.isRequired,
};

export default BoardingPassCard;
