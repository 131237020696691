import moment from 'moment-timezone';

export const getDate = (timestamp) => timestamp && (moment(timestamp, 'MM-DD-YYYY HH:mm:ss').format('DD MMM'));

export const getTime = (timestamp) => timestamp && moment(timestamp, 'MM-DD-YYYY HH:mm:ss').format('HH:mm');

export const getTimeDifference = (t1, t2) => moment(moment(t2, 'MM-DD-YYYY HH:mm:ss').diff(moment(t1, 'MM-DD-YYYY HH:mm:ss'))).format('H[h] mm[min]');

const timeConvert = (n) => {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}h ${rminutes}min`;
};

export const getTimeDifferenceWithTimeZone = (t1, t2, t1tz, t2tz) => {
  try {
    const time1adjusted = moment.tz(t1, 'MM-DD-YYYY HH:mm:ss', true, t1tz);
    if (t1tz === 'America/Nuuk') {
      time1adjusted.subtract(1, 'hour');
    }
    const time2adjusted = moment.tz(t2, 'MM-DD-YYYY HH:mm:ss', true, t2tz);
    if (t2tz === 'America/Nuuk') {
      time2adjusted.subtract(1, 'hour');
    }
    const timeDiff = time2adjusted.diff(time1adjusted, 'minutes');
    const timeformated = timeConvert(timeDiff);
    return timeformated;
  } catch {
    return '';
  }
};
