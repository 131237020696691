import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';

const useStyles = makeStyles(() => ({
  body: {
    minHeight: 'calc(100vh - 141px)',
    height: '80vh',
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  const { children } = props;

  return (
    <>
      <Header />
      <main className={classes.body}>
        {children}
      </main>
    </>
  );
};

export default Layout;
