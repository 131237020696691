import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CancelCheckinIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import SeatmapIcon from '@material-ui/icons/EventSeat';

import { SeatMapContext } from '../../../../../context/seatMap';
import { CheckinContext } from '../../../../../context/checkin';
import { BookingContext } from '../../../../../context/booking';

import Text from '../../../../UI/Text/Text';

const useStyles = makeStyles((theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    color: theme.palette.warning.main,
  },
  warningIcon: {
    height: 18,
    marginRight: 3,
  },
  actionCheckInButtons: {
    width: 'inherit',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Actions = (props) => {
  const classes = useStyles();

  const {
    flights,
    journeyIndex,
    setLoadingBar,
  } = props;

  // statuses
  const status = Object.freeze({
    open: 'Open',
    gated: 'Gated',
    finalised: 'Finalised',
    departed: 'Departed',
  });

  const [checkinLoading, setCheckinLoading] = useState(false);
  const [cancelCheckinLoading, setCancelCheckinLoading] = useState(false);

  const checkedInFlight = flights
    .find((flight) => flight.passengers.find((passenger) => passenger.checkin))
      && flights.find((flight) => flight.passengers
        .find((passenger) => passenger.checkin)).passengers
        .map((passenger) => passenger.checkin);

  const printedBoarding = flights[0].passengers.find((passenger) => passenger.statuses?.boardingPass === 'Printed');
  // const cphFlight = flights
  //  .find((flight) => flight.station && flight.station.from &&
  // flight.station.from.code === 'CPH');

  const {
    checkin,
    cancelCheckin,
  } = useContext(CheckinContext);

  const {
    refetchBooking,
  } = useContext(BookingContext);

  const {
    setOpenSeatMap,
    // cancelSeat,
  } = useContext(SeatMapContext);

  const handleSeatMap = () => {
    setOpenSeatMap(journeyIndex);
  };

  const handelCancelCheckin = () => {
    setCancelCheckinLoading(true);
    // cancelSeat(flights[0].passengers, journeyIndex);
    cancelCheckin(journeyIndex).then(() => {
      refetchBooking().then(() => setCancelCheckinLoading(false));
    });
  };
  const handelCheckin = () => {
    setCheckinLoading(true);
    checkin(journeyIndex).then(() => {
      refetchBooking().then(() => setCheckinLoading(false));
    });
  };

  setLoadingBar(checkinLoading || cancelCheckinLoading);

  const flightOpen = (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
    >
      {checkedInFlight ? (
        <>
          <Box m={1}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<CancelCheckinIcon />}
              className={classes.cancelCheckinButton}
              onClick={() => handelCancelCheckin()}
              disabled={printedBoarding}
            >
              <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-cancel-checkin" /></Typography>
            </Button>
          </Box>

          <Box m={1}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<SeatmapIcon />}
              onClick={() => handleSeatMap()}
              disabled={printedBoarding}
            >
              <Typography color="textSecondary" variant="caption"><Text id="activeJourney-flight-card-action-seatmap" /></Typography>
            </Button>
          </Box>
        </>
      )
        : (
          <Button
            className={classes.actionCheckInButtons}
            fullWidth
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => handelCheckin()}
          >
            <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-checkin" /></Typography>
          </Button>

        )}
    </Box>
  );

  const flight = flights[0];

  let statusCompnent = <></>;

  if (flight && flight.statuses.acceptance === status.gated) {
    statusCompnent = (
      <Box className={classes.warning}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="caption"><Text id="flight-status-warning-checkin-gated" /></Typography>
      </Box>
    );
  } else if (flight && flight.statuses.acceptance === status.departed) {
    statusCompnent = (
      <Box className={classes.warning}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="caption"><Text id="flight-status-warning-checkin-departed" /></Typography>
      </Box>
    );
  } else if (flight && flight.statuses.acceptance === status.finalised) {
    statusCompnent = (
      <Box className={classes.warning}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="caption"><Text id="flight-status-warning-checkin-finalised" /></Typography>
      </Box>
    );
  } else if (flight && flight.statuses.general === 'Cancelled') {
    statusCompnent = (
      <Box className={classes.warning}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="caption"><Text id="flight-status-warning-checkin-cancelled" /></Typography>
      </Box>
    );
  } else if (flight && flight.statuses.acceptance === status.open) {
    statusCompnent = (
      flightOpen
    );
  } else {
    statusCompnent = (
      <Box className={classes.warning}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="caption"><Text id="flight-status-warning-checkin-not-open" /></Typography>
      </Box>
    );
  }

  return (statusCompnent);
};

export default Actions;
