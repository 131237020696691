import React from 'react';

import en from './en.json';
import dk from './dk.json';
import gl from './gl.json';

import { ReactComponent as FlagEN } from '../assets/img/flags/flagEN.svg';
import { ReactComponent as FlagDK } from '../assets/img/flags/flagDK.svg';
import { ReactComponent as FlagGL } from '../assets/img/flags/flagGL.svg';

export const dictionaryList = { en, dk, gl };

export const languages = [
  {
    text: 'English',
    value: 'en',
    icon: <FlagEN />,
  },
  {
    text: 'Dansk',
    value: 'dk',
    icon: <FlagDK />,
  },
  {
    text: 'Kalaallisut',
    value: 'gl',
    icon: <FlagGL />,
  },
];
