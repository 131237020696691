import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';

const CustomInput = withStyles({
  input: {
    display: 'flex',
    justifycontent: (props) => props.justifycontenttext,
    padding: 10,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 8,
    },
  },
})(InputBase);

// Styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    minWidth: 150,
    borderRadius: 8,
  },
  select: {
    height: 38,
  },
  menuContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Select = (props) => {
  const classes = useStyles();

  const {
    items, className, variant, label, ...other
  } = props;

  return (
    <FormControl
      variant={variant || 'filled'}
      className={clsx(classes.formControl, className)}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <MaterialSelect
        className={classes.select}
        input={<CustomInput />}
        {...other}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Box className={classes.menuContent}>
              {item.icon
                && (
                <Box mr={1} className={classes.icon}>
                  {item.icon}
                </Box>
                )}
              {item.text}
            </Box>
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default Select;
