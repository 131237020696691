import React from 'react';
import PropTypes from 'prop-types';

import { Dialog as MaterialDialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Dialog = (props) => {
  const {
    open, setOpen, title, children, action, maxWidth,
  } = props;

  return (
    <MaterialDialog
      maxWidth={maxWidth || 'sm'}
      open={open}
      fullWidth
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {action}
      </DialogActions>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  action: PropTypes.object,
};

export default Dialog;
