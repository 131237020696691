import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CircularProgress } from '../../../assets/img/loader.svg';

const useStyles = makeStyles((theme) => ({
  rotate: {
    animation: '$rotation 1.5s infinite ease-in-out',
    'transform-origin': 'center center',
    marginTop: (props) => (props.noMargin ? 0 : theme.spacing(15)),
  },
  '@keyframes rotation': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  color: (props) => props.color && props.color,
  marginTop: theme.spacing(2.5),
}));

const Loading = (props) => {
  const classes = useStyles(props);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress className={classes.rotate} height={100} />
    </Box>
  );
};

Loading.propTypes = {
};

export default Loading;
