/* eslint-disable no-unused-vars */
import axios from 'axios';

// API domain
let domain = 'https://pot.test.gl.objective.se/api';
if (process.env.REACT_APP_PURPOSE_BACKEND_API) {
  domain = process.env.REACT_APP_PURPOSE_BACKEND_API;
}

// Error
const handleNetworkError = (error) => {
  console.log(error);
};

// GET
export const get = (path, successFunction, errorFunction) => axios
  .get(domain + path)
  .then((response) => successFunction(response.data))
  .catch((error) => handleNetworkError(error));

// PATCH
export const patch = (path, body, successFunction, errorFunction) => axios
  .patch(domain + path, body)
  .then((response) => successFunction(response.data, body))
  .catch((error) => handleNetworkError(error));

// POST
export const post = (path, body, successFunction, errorFunction) => axios
  .post(domain + path, body)
  .then((response) => successFunction(response.data))
  .catch((error) => handleNetworkError(error));

// DELETE
export const del = (path, body, successFunction, errorFunction) => axios
  .delete(domain + path, { data: body })
  .then((response) => successFunction(response.data))
  .catch((error) => handleNetworkError(error));
