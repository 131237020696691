import { createMuiTheme } from '@material-ui/core';
import merge from 'lodash/merge';
import colors from './colors';
import typography from './typography';
import overrides from './overrides';

// Create Material UI theme
const defaultMuiTheme = createMuiTheme({
  palette: {
    background: {
      default: colors.background.main,
    },
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
    success: {
      main: colors.success.main,
    },
    error: {
      main: colors.error.main,
    },
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
    warning: {
      main: colors.warning.main,
      icon: colors.warning.icon,
    },
  },
  typography: {
  },
});

defaultMuiTheme.overrides = merge(
  defaultMuiTheme.overrides,
  overrides(defaultMuiTheme),
);

defaultMuiTheme.typography = merge(
  defaultMuiTheme.typography,
  typography(defaultMuiTheme),
);

export default defaultMuiTheme;
