/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Box, Button } from '@material-ui/core';
import FlightRoute from '../../../UI/FlightRoute/FlightRoute';
import { BoardingPassContext } from '../../../../context/boardingPass';
import { BookingContext } from '../../../../context/booking';

const useStyles = makeStyles({
  root: {
    padding: 1,
  },
});

const BoardingPassCard = (props) => {
  const {
    segmentFlights,
    pax,
    segmentIndex,
    flights,
    walletType,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    fetchBoardingAppleWallet,
    fetchBoardingGoogleWallet,
  } = useContext(BoardingPassContext);

  const {
    booking,
  } = useContext(BookingContext);

  const getFlight = () => {
    let lastPassengerFlightIdentifier;
    const flightsRequest = [];
    // eslint-disable-next-line array-callback-return
    flights.map((flight) => {
      // eslint-disable-next-line max-len
      const foundPassenger = flight.passengers.find((p) => (p.passengerIdentifier === pax.passengerIdentifier));
      if (foundPassenger && foundPassenger.checkin
          && flights[segmentIndex].flightTime.scheduledDeparture
          && foundPassenger.passengerFlightIdentifier !== lastPassengerFlightIdentifier) {
        lastPassengerFlightIdentifier = foundPassenger.passengerFlightIdentifier;

        flightsRequest.push({
          departureDate: flight.flightTime.scheduledDeparture,
          flightNumber: flight.flightNr,
          fromStationCode: flight.station.from.code,
          marketingCarrier: flight.carrier,
          passengerFlightIdentifier: foundPassenger.passengerFlightIdentifier,
        });
      }
    });
    return flightsRequest;
  };

  const request = () => booking && {
    customers: ([{
      flights: getFlight(),
      surname: pax.name.surName,
    }]),
    pnr: pax.pnr,
    station: flights[0].station.from.code,
    segment: segmentIndex,
  };

  return (
    <>
      <Box
        style={{ width: '100%' }}
        disabled={loading}
        onClick={(() => {
          if (pax && walletType === 'Apple') {
            setLoading(true);
            fetchBoardingAppleWallet(request()).then(() => {
              setLoading(false);
            });
          }
          if (pax && walletType === 'Google') {
            setLoading(true);
            fetchBoardingGoogleWallet(request()).then((res) => {
              setLoading(false);
              window.open(res, '_blank');
            });
          }
        })}
      >
        <Grid container spacing={1}>
          <Grid item xs={9}>
            {segmentFlights[segmentIndex].map((flight) => (
              <FlightRoute
                scheduledDeparture={flight.flightTime.scheduledDeparture}
                scheduledArrival={flight.flightTime.scheduledArrival}
                className={classes.root}
                flightNumber={flight.carrier + flight.flightNr}
                departureStationCode={
                  <Typography variant="h4">{(flight.station && flight.station.from) ? flight.station.from.code : 'N/A'}</Typography>
            }
                departureStationName={(flight.station && flight.station.from) ? flight.station.from.name : 'N/A'}
                arrivalStationCode={
                  <Typography variant="h4">{(flight.station && flight.station.to) ? flight.station.to.code : 'N/A'}</Typography>
            }
                arrivalStationName={(flight.station && flight.station.to) ? flight.station.to.name : 'N/A'}
              />
            ))}
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              style={{
                padding: 2,
                height: '100%',
                display: 'flex',
              }}
            >
              Add to wallet
            </Button>
          </Grid>
        </Grid>
      </Box>
      { loading && (
        <LinearProgress noMargin />
      )}
    </>
  );
};

BoardingPassCard.propTypes = {
  flights: PropTypes.object.isRequired,
};

export default BoardingPassCard;
