import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const SectionTitle = (props) => {
  const { text } = props;

  return (
    <Box mt={1}>
      <Typography variant="h4">
        {text.toUpperCase()}
      </Typography>
    </Box>
  );
};

SectionTitle.propTypes = {
  text: PropTypes.string,
};

export default SectionTitle;
