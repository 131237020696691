import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { getDate } from '../../../utils/date';

const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#e0f4fd',
    padding: '8px',
  },
}));

const PreviewFlightInfo = (props) => {
  const classes = useStyles();

  const {
    journey,
  } = props;

  return (
    <Box my={1} className={classes.box}>
      <Box display="flex" flexWrap="wrap">
        {journey && journey.map((flight) => (
          <Fragment key={flight.flightNr + flight.station.from.code}>
            <Typography variant="h5">
              {` ${flight.station.from.name} -`}
            </Typography>
            <Box display="flex" flexDirection="row" justifyItems="center">
              &nbsp;
            </Box>
          </Fragment>
        ))}
        <Typography variant="h5">
          {journey[journey.length - 1].station.to.name}
        </Typography>
      </Box>
      <Typography variant="h6">
        {`${getDate(journey[0].flightTime.scheduledDeparture)}`}
      </Typography>
    </Box>
  );
};

PreviewFlightInfo.propTypes = {
  journey: PropTypes.array,

};

export default PreviewFlightInfo;
