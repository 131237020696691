/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CryptoJS from 'crypto-js';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import SearchForm from '../../components/BookingSearch/SearchForm';
import Loading from '../../components/UI/Loading/Loading';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';
import Notification from '../../components/UI/Notification/Notification';

import Background from '../../assets/img/background.jpg';
import LogoAirGreenlandTrapezium from '../../assets/img/LogoAirGreenlandTrapezium.png';
import { wwwDir } from '../../utils/cordovaGlobals';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 'auto',
    maxWidth: '60vw',
    width: '250px',
    [theme.breakpoints.up('xl')]: {
      width: '400px',
    },
  },
  backgroundMobil: {
    backgroundImage: `url(${wwwDir}${Background})`,
    backgroundPosition: 'right',
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'none',
    },
  },
  backgroundDesktop: {
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
    float: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const BookingSearch = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(true);
  const classes = useStyles();

  const {
    setStep,
    setAppEmbedded,
  } = useContext(NavigationContext);

  const {
    booking,
    bookingError,
    fetchBookingByPNRAndName,
    fetchBookingByTicketAndName,
    fetchBookingByPNRandPI,
    fetchBookingByPNR,
    clearBooking,
    setPassengerIdentifier,
    setAutoCheckin,
  } = useContext(BookingContext);

  useEffect(() => {
    setStep(-1);
  }, [setStep]);

  useEffect(() => {
    if (loading && booking) {
      history.push(routes.DANGEROUS_GOODS);
    } else {
      clearBooking();
    }
  }, [booking, loading, clearBooking, history]);

  useEffect(() => {
    if (bookingError) {
      setLoading(false);
    }
  }, [bookingError, setLoading]);

  const decryptData = (cipherText) => {
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(cipherText) },
      CryptoJS.enc.Hex.parse(process.env.REACT_APP_URL_DECRYPT_KEY),
      { iv: CryptoJS.enc.Hex.parse(process.env.REACT_APP_URL_DECRYPT_IV), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 },
    );
    return CryptoJS.enc.Utf8.stringify(decrypted);
  };

  useEffect(() => {
    if (query.get('pi')) {
      setPassengerIdentifier(query.get('pi'));
    }
    if (query.get('autoCheckin')) {
      setAutoCheckin(query.get('autoCheckin'));
    } else if (query.get('ac')) {
      setAutoCheckin(query.get('ac'));
    }
    if (Number(query.get('CTAppEmbedded')) === 1) {
      setAppEmbedded(true);
    }
    if (first) {
      setFirst(false);
      if (query.get('data')) {
        const decryptedString = decryptData(query.get('data'));
        setFirst(true);
        history.push(`/?${decryptedString}`);
      }
      if (query.get('ref') && query.get('pi')) {
        setLoading(true);
        fetchBookingByPNRandPI(query.get('ref'), query.get('pi'));
      }
      if (query.get('bookingRef') && query.get('bookingRef').length === 6 && query.get('lastName')) {
        setLoading(true);
        fetchBookingByPNRAndName(query.get('bookingRef').toUpperCase(), query.get('lastName').toUpperCase());
      } else if (query.get('ref') && query.get('ref').length === 6 && query.get('name')) {
        setLoading(true);
        fetchBookingByPNRAndName(query.get('bookingRef').toUpperCase(), query.get('name').toUpperCase());
      } else if (query.get('ticket-number') && query.get('ticket-number').length >= 6) {
        setLoading(true);
        fetchBookingByTicketAndName(query.get('ticket-number').toUpperCase(), query.get('lastName').toUpperCase());
      }
    }
  }, [query, fetchBookingByPNRAndName, fetchBookingByTicketAndName, fetchBookingByPNRandPI,
    setPassengerIdentifier, setAutoCheckin, setAppEmbedded, setFirst, first, fetchBookingByPNR]);

  const handleSubmitPNR = (pnr, surname) => {
    setLoading(true);
    fetchBookingByPNRAndName(pnr, surname);
  };

  const handleSubmitTicket = (ticket, surname) => {
    let tempTicket = ticket;
    tempTicket = tempTicket.replace('-', '');
    if (tempTicket.length === 15) {
      tempTicket = tempTicket.slice(0, -2);
    }
    setLoading(true);
    fetchBookingByTicketAndName(tempTicket.trim(), surname.trim());
  };

  let errorNotification;
  if (bookingError) {
    errorNotification = <Notification severity="error" message={<Text id="booking-search-error" />} />;
  }

  const logo = (window.innerHeight > 600) && (
  <Box position="absolute" bottom={{ xs: '10px', sm: '8vh' }} right={0} zIndex={99}>
    <img src={`${wwwDir}${LogoAirGreenlandTrapezium}`} alt="Logo AirGreenland" className={classes.logo} />
  </Box>
  );

  return (
    loading ? (
      <Loading />
    ) : (
      <Box height="100vh" width="100vw" position="absolute" top={0} left={0} className={classes.backgroundMobil}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={false} lg={7}>
            <img className={classes.backgroundDesktop} src={`${wwwDir}${Background}`} alt="background" />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box mt="20vh" position="relative" zIndex={101}>
              <Container maxWidth="xs">
                <Paper square>
                  <Box p={2}>
                    <Box pt={3}>
                      <Typography variant="h1"><Text id="booking-search-title" /></Typography>
                    </Box>
                    <Box>
                      <Typography><Text id="booking-search-description" /></Typography>
                    </Box>
                    <SearchForm
                      handleSubmitPNR={handleSubmitPNR}
                      handleSubmitTicket={handleSubmitTicket}
                    />
                    {errorNotification}
                  </Box>
                </Paper>
              </Container>
            </Box>
          </Grid>
        </Grid>
        {logo}
      </Box>
    )
  );
};

export default BookingSearch;
