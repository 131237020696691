import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CheckinIcon from '@material-ui/icons/Edit';
// import LuggageIcon from '@material-ui/icons/Work';
import WarningIcon from '@material-ui/icons/Warning';

import Text from '../../../../UI/Text/Text';

const useStyles = makeStyles((theme) => ({
  checkinButton: {
    marginRight: 18,
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.warning.main,
  },
  warningIcon: {
    height: 18,
    marginRight: 3,
  },
}));

const Actions = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    flight,
  } = props;

  const handleCheckin = () => {
    history.push('/check-in');
  };

  // statuses
  const status = Object.freeze({
    open: 'Open',
    gated: 'Gated',
  });

  let statusCompnent = <></>;
  switch (flight && flight.status) {
    case status.open:
      statusCompnent = (
        <Box display="flex" alignItems="center" width="100%">
          <Button
            size="small"
            color="secondary"
            startIcon={<CheckinIcon />}
            className={classes.checkinButton}
            variant="contained"
            onClick={handleCheckin}
          >
            <Typography color="textSecondary" variant="caption"><Text id="summary-card-action-checkin" /></Typography>
          </Button>
          {/* <Button
            size="small"
            color="secondary"
            startIcon={<LuggageIcon />}
          >
            <Typography color="secondary" variant="caption">
              <Text id="summary-card-action-luggage" />
            </Typography>
          </Button> */}
        </Box>
      );
      break;

    default:
      statusCompnent = (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="caption"><Text id="summary-card-warning-checkin-not-open" /></Typography>
        </Box>
      );
  }

  return (<>{statusCompnent}</>);
};

Actions.propTypes = {
  flight: PropTypes.object.isRequired,
};

export default Actions;
