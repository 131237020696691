/* eslint-disable no-nested-ternary */
import React, { useState, createContext, useContext, useEffect } from 'react';

import * as api from '../apiPurpose';
import { BookingContext } from './booking';
import { LanguageContext } from './language';

export const PurposeOfTravelContext = createContext();

export const PurposeOfTravelProvider = (props) => {
  const { children } = props;

  const [purposeList, setPurposeList] = useState();
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [initSelectedPurpose, setInitSelectedPurpose] = useState('');
  const [purposeListError, setPurposeListError] = useState();
  const [getByPnrPurposeOfTravelError, setGetByPnrPurposeOfTravelError] = useState();
  const [addPurposeOfTravelError, setAddPurposeOfTravelError] = useState();

  const {
    unfilteredBooking,
  } = useContext(BookingContext);
  const { language } = useContext(LanguageContext);

  const pnr = (unfilteredBooking && unfilteredBooking.primeJourney.length > 0)
    && unfilteredBooking.primeJourney[0].passengers[0].pnr;

  const addPurposeOfTravel = () => {
    setAddPurposeOfTravelError();
    const request = {
      pnr,
      purposeId: selectedPurpose,
    };

    return api.post(
      '/add',
      request,
      (res) => res,
      setAddPurposeOfTravelError,
    );
  };

  useEffect(() => {
    const getByPnrPurposeOfTravel = () => {
      setGetByPnrPurposeOfTravelError();
      return api.get(
        `/byPnr?pnr=${pnr}`,
        (purpose) => {
          setSelectedPurpose((purpose.length > 0) ? purpose[purpose.length - 1].purpose.id : '');
          setInitSelectedPurpose((purpose.length > 0) ? purpose[purpose.length - 1].purpose.id : '');
        },
        setGetByPnrPurposeOfTravelError,
      );
    };
    const fetchPurposeList = () => {
      setPurposeListError();
      api.get(
        '/purpose/all',
        (b) => setPurposeList(b),
        setPurposeListError,
      ).then(() => {
        if (pnr) {
          getByPnrPurposeOfTravel();
        }
      });
    };
    fetchPurposeList();
  }, [pnr]);

  const purposeListForSelector = purposeList && (purposeList.map((purpose) => ({
    text: (language === 'dk' ? purpose.purposeDk : language === 'gl' ? purpose.purposeKl : purpose.purpose),
    value: purpose.id,
  })));

  const values = {
    purposeList,
    purposeListForSelector,
    purposeListError,
    selectedPurpose,
    setSelectedPurpose,
    addPurposeOfTravel,
    getByPnrPurposeOfTravelError,
    addPurposeOfTravelError,
    initSelectedPurpose,
    setInitSelectedPurpose,
  };

  return (
    <PurposeOfTravelContext.Provider value={values}>
      {children}
    </PurposeOfTravelContext.Provider>
  );
};
