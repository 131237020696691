import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Text from '../../../UI/Text/Text';

import SeatMap from '../../../UI/SeatMap';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    backgroundImage: 'linear-gradient(#ffffff00 , white 40%)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
}));

const Card = (props) => {
  const {
    seatmap,
    flight,
    setSeatUpdateSuccess,
    activePassenger,
    setSeatChanged,
    updateSeat,
    getFDSeatMapError,
    fetchPrivSeatMap,
  } = props;
  const classes = useStyles();

  const [initials, setInitials] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState(null);
  const [selectedPassengersWithSeats, setSelectedPassengersWithSeats] = useState([]);
  const [seatUpdateLoading, setSeatUpdateLoading] = useState(false);

  // Filter out infants
  const { passengers } = flight;

  useEffect(() => {
    const filteredPassengers = passengers && passengers;
    setInitials(filteredPassengers.map((passenger) => passenger.initials));
    setSelectedSeats(filteredPassengers.map((passenger) => passenger.seat));
  }, [passengers]);

  // Select seat
  const handleSelectSeat = (selected) => {
    const filteredPassengers = passengers && passengers;

    let newList;
    if (selectedPassengersWithSeats.length < 1) {
      newList = [filteredPassengers[activePassenger]];
      setSelectedPassengersWithSeats([
        ...selectedPassengersWithSeats,
        ...newList,
      ]);
    } else {
      const selectedPassengerIdentifier = filteredPassengers[activePassenger].passengerIdentifier;
      newList = selectedPassengersWithSeats;
      selectedPassengersWithSeats.map((pax) => {
        if (pax.passengerIdentifier.includes(selectedPassengerIdentifier)) {
          newList.splice(activePassenger, 1, filteredPassengers[activePassenger]);
          return newList;
        } if (filteredPassengers.length > selectedPassengersWithSeats.length) {
          newList.splice(activePassenger, 0, filteredPassengers[activePassenger]);
          setSelectedPassengersWithSeats(newList);
        }
        return newList;
      });
    }

    const newPassengerSeats = selectedSeats.map((current, i) => (
      activePassenger === i ? selected : current));
    setSelectedSeats(newPassengerSeats);
    setSeatChanged(newPassengerSeats);
  };

  const handelUpdateSeat = () => {
    setSeatUpdateLoading(true);
    return updateSeat().then((res) => {
      if (res && !res.error) {
        setSeatUpdateSuccess(true);
      }
      return null;
    });
  };

  return (
    <>
      {!getFDSeatMapError
        && (
          <SeatMap
            initials={initials}
            selectedSeats={selectedSeats}
            selectSeat={activePassenger !== null ? handleSelectSeat : null}
            activePassenger={activePassenger}
            flight={flight}
            seatmap={seatmap}
          />
        )}
      {getFDSeatMapError && getFDSeatMapError.code === '17261'
        && (
        <Box textAlign="center" pt={4}>
          <Typography variant="h2">Free seating</Typography>
        </Box>
        )}

      <Box height={80} />
      <Box
        p={1}
        position="absolute"
        bottom={0}
        mx="auto"
        px="5%"
        pb="20px"
        width="50%"
        pt="55px"
        className={classes.actionButtons}
        style={{

        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => fetchPrivSeatMap()}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => handelUpdateSeat().then(() => setSeatUpdateLoading(false))}
            >
              <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-seatmap-update" /></Typography>
            </Button>
          </Grid>
        </Grid>
        {seatUpdateLoading && <LinearProgress />}
      </Box>
    </>
  );
};

export default Card;
