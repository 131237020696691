import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as routes from '../../Routes';

const Navigation = (props) => {
  const history = useHistory();

  const {
    backText,
    nextText,
    backRoute,
    nextRoute,
    disableNext = false,
    onClickNext,
    nextAlink,
    postMessage,
  } = props;

  const LinkWrapper = ({ children }) => (
    nextAlink ? (
      <a href={nextAlink} style={{ textDecoration: 'none' }} target="_top">
        {children}
      </a>
    ) : children
  );

  const LinkWrapperBack = ({ children }) => ((backRoute === '/?canceled=true' && (postMessage && window.location !== window.parent.location)) ? (
    <a href={`${routes.BOOKING_SEARCH}?canceled=true`} style={{ textDecoration: 'none' }} target="_top">
      {children}
    </a>
  ) : children);

  const onClick = () => {
    if (onClickNext) {
      onClickNext();
    }
    if (window.location !== window.parent.location) {
      if (postMessage) {
        window.parent.postMessage(postMessage, '*');
      }
    }
    if (!nextAlink) {
      history.push(nextRoute);
    }
  };

  return (
    <Box mb={2}>
      <Grid container spacing={1}>
        {backRoute && (
          <Grid item xs={4}>
            <LinkWrapperBack>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => history.push(backRoute)}
              >
                {backText || 'Back'}
              </Button>
            </LinkWrapperBack>
          </Grid>
        )}
        {(nextRoute || onClickNext) && (
          <Grid item xs={8}>
            <LinkWrapper>
              <Button
                fullWidth
                disabled={disableNext}
                color="secondary"
                variant="contained"
                onClick={() => onClick()}
              >
                {nextText || 'Continue'}
              </Button>
            </LinkWrapper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Navigation.propTypes = {
  backText: PropTypes.string,
  nextText: PropTypes.string,
  backRoute: PropTypes.string,
  nextRoute: PropTypes.string,
};

export default Navigation;
