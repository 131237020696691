/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SummaryJourney from './SummaryJourney/SummaryJourney';
import BoardingPassCard from './BoardingPassCard/BoardingPassCard';

const BoardingPassCardList = (props) => {
  const {
    flights,
    pax,
    walletType,
  } = props;

  function convertDate(dateValue) {
    const splitted = dateValue.split(' ');
    const [month, day, year] = splitted[0].split('-');
    const resultTemp = [year, month, day].join('-');
    const result = [resultTemp, splitted[1]].join('T');
    return result;
  }

  function sortFunction(objA, objB) {
    const part1 = new Date(convertDate(objA[0].flightTime.scheduledDeparture));
    const part2 = new Date(convertDate(objB[0].flightTime.scheduledDeparture));
    return part1 - part2;
  }

  const filterFlights = (flightList) => {
    const obj = flightList.reduce((res, curr) => {
      if (res[curr.flightNr] && res[curr.flightNr][0].carrier === curr.carrier) {
        res[curr.flightNr].push(curr);
      } else Object.assign(res, { [curr.flightNr]: [curr] });
      return res;
    }, {});

    const filterSegmentTemp = Object.values(obj);
    return filterSegmentTemp.sort(sortFunction);
  };

  const flightSegment = filterFlights(flights);

  const checkedInFlight = flights
    .find((flight) => flight.status === 'Open' && flight.passengers.find((passenger) => passenger.checkin))
    && flights.find((flight) => flight.passengers
      .find((passenger) => passenger.checkin)).passengers
      .map((passenger) => passenger.checkin);

  const filteringFlights = flightSegment.map((flight, i) => {
    if (flight[0] && flight[0].status !== 'Departed'
            && flight[0].passengers.find((passenger) => passenger.checkin)) {
      return (
        <Box pb={0.5} key={flight[0].flightNr + flight[0].station.from.code}>
          <BoardingPassCard
            segmentFlights={flightSegment}
            flights={flights}
            segmentIndex={i}
            pax={pax}
            walletType={walletType}
          />
        </Box>
      );
    } return null;
  });

  return (
    <>
      {checkedInFlight ? (
        <Box pt={1}>
          <Typography>{pax && pax.name.fullName}</Typography>
          <Box mt={1} mb={0.5}>
            <SummaryJourney
              flights={flights}
            />
          </Box>
          {filteringFlights}
        </Box>
      ) : null }
    </>
  );
};

BoardingPassCardList.propTypes = {
  flights: PropTypes.array,
};

export default BoardingPassCardList;
