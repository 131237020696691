import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import React from 'react';

const MultiValueSearchField = (props) => {
  // Props
  const {
    id,
    variant,
    dense,
    error,
    helperText,
    placeholder,
    label,
    items,
    setValues,
  } = props;

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Autocomplete
      multiple
      id="tags-outlined"
      options={items}
      getOptionLabel={(option) => option.name.fullName}
      filterSelectedOptions
      onChange={(event, newValue) => { setValues(newValue); }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          label={label}
          variant={variant || 'outlined'}
          margin={dense && 'dense'}
          placeholder={placeholder || ''}
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  /* eslint-enable react/jsx-props-no-spreading */
  );
};

export default MultiValueSearchField;
