import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import SummaryCard from './SummaryCard/SummaryCard';

const SummaryCardList = (props) => {
  const {
    journeys,
  } = props;

  return (journeys ? journeys.map((journey, index) => (
    <Box pb={2} key={journey[0].flightNr + journey[0].station.from.code}>
      <SummaryCard open={journeys.length < 2} flights={journey} index={index} />
    </Box>
  )) : <></>
  );
};

SummaryCardList.propTypes = {
  journeys: PropTypes.array,
};

export default SummaryCardList;
