import React, { useContext, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { NavigationContext } from '../../context/navigation';
import Navigation from '../../hoc/Navigation/Navigation';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';

import { ReactComponent as Battery } from '../../assets/img/dangerous-goods/battery.svg';
import { ReactComponent as Compressed } from '../../assets/img/dangerous-goods/compressed.svg';
import { ReactComponent as Corrosives } from '../../assets/img/dangerous-goods/corrosives.svg';
import { ReactComponent as Explosive } from '../../assets/img/dangerous-goods/explosive.svg';
import { ReactComponent as Flammable } from '../../assets/img/dangerous-goods/flammable.svg';
import { ReactComponent as Oxidizer } from '../../assets/img/dangerous-goods/oxidizer.svg';
import { ReactComponent as Poisons } from '../../assets/img/dangerous-goods/poisons.svg';
import { ReactComponent as Radiation } from '../../assets/img/dangerous-goods/radiation.svg';
import SelectPassenger from '../SelectPassenger';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 100,
    width: 100,
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: 80,
    },
  },
}));

const Summary = () => {
  const {
    setStep,
  } = useContext(NavigationContext);
  const classes = useStyles();

  useEffect(() => {
    setStep(-1);
  }, [setStep]);

  return (
    <Container maxWidth="md">
      <SelectPassenger />

      <Box mt={2} px={{ xs: 0, md: 10 }} pb={5}>
        <Box mb={5}>

          <Typography variant="h2"><Text id="dangerous-goods-title" /></Typography>

          <Typography><Text id="dangerous-goods-text" /></Typography>

          <Box textAlign="center">
            <Grid container>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Battery className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-lithium-batteries" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Compressed className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-compressed-gases" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Corrosives className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-corrosives" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Explosive className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-explosives" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Flammable className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-flammable-solids-and-liquids" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Oxidizer className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-oxidizers" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Poisons className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-poisons" /></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={3} xs={4} item>
                <Box my={{ xs: 2, md: 5 }}>
                  <Radiation className={classes.icon} />
                  <Box mt={2}>
                    <Typography variant="h4"><Text id="dangerous-goods-radioactive-materials" /></Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Container maxWidth="sm">
          <Navigation
            backText={<Text id="footer-back-button" />}
            nextText={<Text id="footer-continue-button" />}
            backRoute={`${routes.BOOKING_SEARCH}?canceled=true`}
            nextRoute={routes.PURPOSE_OF_TRAVEL}
            postMessage="/PURPOSE_OF_TRAVEL"
          />
        </Container>

      </Box>
    </Container>
  );
};

export default Summary;
