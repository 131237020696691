import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

// Styles
const useStyles = makeStyles(() => ({
  alert: {
    width: '100%',
    borderRadius: '3px',
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const ErrorMessage = (props) => {
  const classes = useStyles();

  // Props
  const { error, errors } = props;

  let content;
  if (error) {
    content = (
      <Grid container justify="center" direction="row" alignItems="center">
        <Alert className={classes.alert} severity="error">{error}</Alert>
      </Grid>
    );
  } else if (errors) {
    content = (
      <Grid container justify="center" direction="row" alignItems="center">
        {errors.map((err) => (
          <Grid item key={err} className={classes.contentList}>
            <Alert className={classes.alert} severity="error">{err.text}</Alert>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      {content}
    </>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.array,
};

export default ErrorMessage;
